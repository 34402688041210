::-webkit-input-placeholder { /* WebKit browsers */ color: #999; text-align:left;} 
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */ color: #999; opacity: 1; text-align:left;} 
::-moz-placeholder { /* Mozilla Firefox 19+ */ color: #999; opacity: 1; text-align:left; } 
:-ms-input-placeholder { /* Internet Explorer 10+ */ color: #999; text-align:left;} 
.appearance{-webkit-appearance: none;
-moz-appearance: none;
-ms-appearance: none;
-o-appearance: none;
appearance: none;}
select::-ms-expand {
    display: none;
}
select
{box-sizing:border-box;
-webkit-appearance: none; /* 화살표 없애기 for chrome*/
-moz-appearance: none;    /* 화살표 없애기 for firefox*/
appearance: none;         /* 화살표 없애기 공통*/}
input:-webkit-autofill { -webkit-box-shadow: 0 0 0 30px #fff inset ; -webkit-text-fill-color: #000; }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

/* custom_check */
.custom_check label{display: -webkit-box;display: -ms-flexbox;display: -webkit-flex;display: flex; -webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;cursor: pointer;}
.custom_check label input{display: none;-webkit-appearance: none;-moz-appearance: none;-ms-appearance: none;-o-appearance: none;appearance: none;}
.custom_check label .check{width: 18px;height: 18px;font-size: 0;border:1px solid #ddd;background: #fff;}
.custom_check label .txt{font-size: 14px;color: #333;padding-left: 8px;}
.custom_check label input:checked + .check{background:#fff url(../../images/check.svg) no-repeat center;border-color: #008ecd;}

/* custom_radio */
.custom_radio label{display: -webkit-box;display: -ms-flexbox;display: -webkit-flex;display: flex; -webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;cursor: pointer;}
.custom_radio label input{display: none;-webkit-appearance: none;-moz-appearance: none;-ms-appearance: none;-o-appearance: none;appearance: none;}
.custom_radio label .check{width: 18px;height: 18px;font-size: 0;border:1px solid #ddd;background: #fff;border-radius: 50%;position: relative;}
.custom_radio label .check::after{position: absolute;content: '';width: 10px;height: 10px;background: #008ecd;border-radius: 50%;top: 3px;left: 3px;opacity: 0;}
.custom_radio label .txt{font-size: 13px;color: #333;padding-left: 5px;}
.custom_radio label .txt b{font-weight: 400;font-size: 10px;}
.custom_radio label input:checked + .check{border-color: #008ecd;}
.custom_radio label input:checked + .check::after{opacity: 1;}

/* custom_input */
.custom_input{border: 1px solid #ddd;height: 50px;position: relative;}
.custom_input input{width: 100%;height: 100%;border: none;font-size: 15px;color: #222;background: none;padding: 0 15px;background: #fff;}
.custom_input input::-webkit-input-placeholder {color: #999; text-align:left;font-size: 15px;} 
.custom_input input:-moz-placeholder {color: #999; opacity: 1; text-align:left;font-size: 15px;} 
.custom_input input::-moz-placeholder {color: #999; opacity: 1; text-align:left; font-size: 15px;} 
.custom_input input:-ms-input-placeholder {color: #999; text-align:left;font-size: 15px;}
.custom_input.h_30{height: 30px;}
.custom_input.h_30 input{font-size: 13px;padding: 0 10px;}
.custom_input input.f_13{font-size: 13px;padding: 0 10px;}
.custom_input input.f_13::-webkit-input-placeholder{font-size: 13px;}
.custom_input input.f_13:-moz-placeholder {color: #999; opacity: 1; text-align:left;font-size: 15px;} 
.custom_input input.f_13::-moz-placeholder {color: #999; opacity: 1; text-align:left; font-size: 15px;} 
.custom_input input.f_13:-ms-input-placeholder {color: #999; text-align:left;font-size: 15px;}
.custom_input2{border: 1px solid #ddd;height: 40px;}
.custom_input2 input{width: 100%;height: 100%;border: none;font-size: 13px;color: #666;background: none;padding: 0 10px;background: #fff;}
.custom_input2 input::-webkit-input-placeholder {color: #999; text-align:left;font-size: 13px;} 
.custom_input2 input:-moz-placeholder {color: #999; opacity: 1; text-align:left;font-size: 13px;} 
.custom_input2 input::-moz-placeholder {color: #999; opacity: 1; text-align:left; font-size: 13px;} 
.custom_input2 input:-ms-input-placeholder {color: #999; text-align:left;font-size: 13px;}
.custom_input2 input:disabled{background: #f8f8f8;}
.custom_input .react-datepicker-wrapper,.custom_input .react-datepicker__input-container{height: 100%;}
.custom_input input:disabled,.custom_input input:read-only,.custom_input2 input:disabled,.custom_input2 input:read-only{background: #f8f8f8;cursor: default;}
.custom_input.f_15 input{font-size: 15px !important;}
.custom_input.read_only{background: #f8f8f8;}

/* txt_input */
.txt_input {padding-right: 12px;}
.txt_input .txt{position: absolute;color: #999;right: 10px;top: 52%;transform: translateY(-50%);}

/* date_input */
.date_input{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;position: relative;-ms-flex-wrap: wrap;flex-wrap: wrap;max-width: 280px;}
.date_input .custom_input{height: 40px;width: calc(50% - 14px);position: relative;}
.date_input .custom_input:nth-child(2)::after{position: absolute;content: '~';width: 28px;height: 100%;font-size: 15px;color: #222;font-weight: 500;text-align: center;top: 0;left: -28px;line-height: 40px;}
.date_input .custom_input input{height: 38px;padding: 0 30px 0 10px;background:#fff url(../../images/ic_date.svg) no-repeat center right 10px;width: 100%;box-sizing: border-box;font-size: 14px;}
.date_input2{position: relative;}
.date_input2::after{position: absolute;content: '~';width: 18px;height: 100%;top: 0;left:220px;text-align: center;font-size: 13px;line-height: 36px;color: #333;}
.date_input2 .box{width: calc(50% - 9px);margin-right: 18px;}
.date_input2 .box:nth-child(2){margin-right: 0;}
.date_input2 .custom_input{width: 90px;height: 36px;}
.date_input2 .custom_input input{height: 34px;font-size: 14px;padding: 0 5px 0 10px;}
.date_input2 .custom_select{width: 60px !important;height: 36px;}
.date_input2 .custom_select select{height: 100%;font-size: 14px !important;padding: 0 20px 0 10px;}
.date_input3 {-ms-flex-wrap: nowrap;flex-wrap: nowrap;}
.date_input3 .custom_input{height: 30px;}
.date_input3 .custom_input input{height: 28px;font-size: 13px;background: #fff;padding: 0 5px;}
.date_input3 .custom_select select{height: 30px;font-size: 13px !important;padding: 0 20px 0 5px;background-size: 6px;width: 55px;}
.date_input4{-ms-flex-wrap: nowrap;flex-wrap: nowrap;max-width: 100%;-webkit-box-pack: start; -ms-flex-pack: start;-webkit-justify-content: flex-start; justify-content: flex-start;}
.date_input4 .custom_input{width: 120px;}
.date_input4 .custom_select{width: 90px !important;}
.date_select_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;}
.date_select_box>div:first-child{position: relative;margin-right: 28px;}
.date_select_box>div:first-child::after{position: absolute;content: "~";width: 28px;height: 100%;right: -28px;top: 0;text-align: center;line-height: 38px;font-size: 14px;}


/* custom_select */
.custom_select select{border: 1px solid #ddd;height: 40px;padding: 0 25px 0 10px;font-size:15px !important;width: 100%;background: #fff url(../../images/select_arrow.svg) no-repeat center right 10px;border-radius: 0;}
.custom_select select:disabled{background-color: #f8f8f8;}

/* input_box */
.input_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;}
.input_box .custom_input{width: 20%;}
.input_box .custom_input:nth-child(2){width: calc(80% - 10px)}
.input_box2{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;}
.input_box2>div{width: calc(100% - 75px);}
.input_box2>button{width: 70px;height: 40px;line-height: 39px;padding: 0;font-size: 14px;}
.input_box3{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;}
.input_box3>div{width: calc(100% - 95px);}
.input_box3>button{width: 90px;height: 45px;padding: 0;}
.input_box4>div{width: calc(50% - 2.5px);}
.input_box5 {-webkit-box-pack: start; -ms-flex-pack: start;-webkit-justify-content: flex-start; justify-content: flex-start;}
.input_box.input_box5 .custom_input{height: 40px;width: 25%;position: relative;}
.input_box5 .custom_input:first-child{margin-right: 28px;}
.input_box5 .custom_input:first-child::after{position: absolute;content: "~";width: 28px;height: 100%;top: 0;right: -28px;font-size: 14px;text-align: center;line-height: 38px;}
.input_box5 button{height: 40px;font-size: 14px;line-height: 38px;margin-left: 10px;}
.input_box_num{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;}
.input_box_num>div{width: calc(33.333% - 16.666px);position: relative;}
.input_box_num>div::after{position: absolute;content: '-';width: 25px;height: 100%;line-height: 43px;right: -25px;top: 0;text-align: center;font-size: 14px;}
.input_box_num>div:last-child::after{display: none;}
.input_box_email{position: relative;}
.input_box_email::after{position: absolute;content: '@';width: 34px;height: 100%;font-size: 14px;top: 0;left: 50%;transform: translateX(-50%);text-align: center;line-height: 43px;}
.input_box_email>div{width: calc(50% - 17px);}
.drop_input_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;}
.drop_input_box>.drop_wrap{width: 20%;}
.drop_input_box>.custom_input{width: calc(80% - 10px);}

/* custom_text */
.custom_text{border: 1px solid #ddd;}
.custom_text textarea{border: none;height: 100%;display: block;padding: 10px;font-size: 15px;color: #222;}
/* .date_time .react-datepicker {width: 328px;} */