/* 공통 */
.clearfix:after,.clearfix2:after{content:''; display:block; clear:both;}
.clearfix>*,.clearfix2>*{float:left;}
.clearfix2>*:nth-child(2){float: right;}
.ellipsis {display: block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;width:100%;}
.txt_gray{color: #666 !important;}
.txt_gray2{color: #999;}
.txt_black{color: #222 !important;}
.txt_sky{color: #008ecd;}
.underline{text-decoration: underline;}
.letter_narrow{letter-spacing: -2px;}
.h_35{height: 35px;}
.h_40{height: 40px !important;}
.h_45{height: 45px;}
.h_160{height: 160px;}
.h_250{height: 250px;}
.w_25{width: 25% !important;}
.w_100{width: 100% !important;}
.w_180{width: 180px !important;}
.w_49px{width: 49px;}
.w_70px{width: 70px;}
.w_100px{width: 100px;}
.w_120px{width: 120px;}
.w_150px{width: 150px;}
.w_400px{width: 400px;}
.w_600px{width: 600px;}
.w_900px{width: 900px;}
.w_auto{width: auto !important;}
.m_auto{margin: 0 auto;}
.p0{padding: 0 !important;}
.bp0{padding-bottom: 0 !important;}
.rp0{padding-right: 0 !important;}
.lp0{padding-left: 0 !important;}
.flex{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.flex_end{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: end; -ms-flex-pack: end;-webkit-justify-content: flex-end; justify-content: flex-end;}
.flex_between{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.flex_center{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: center; -ms-flex-pack: center;-webkit-justify-content: center; justify-content: center;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.flex_wrap{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.alert_txt{font-size: 14px;color: #ff0000;}
.alert_txt2{font-size: 14px;color: #999;}
.alert_txt2 span{color: #008ecd;}
.none_pro{font-size: 14px;text-align: center;color: #999;padding: 140px 0;}
.none_pro span{color: #222;}
.t_border_none{border-top: none !important;}
.b_border_none{border-bottom: none !important;}
.l_border_none{border-left: none !important;}
.l_border{border-left:1px solid #666 !important;}
.l_border2{border-left:1px solid #ddd !important;}
.t_border{border-top:1px solid #f8f8f8;}
.t_border2{border-top:1px solid #ddd !important;}
.over_txt{overflow-x: auto;overflow-y: hidden;-ms-overflow-style: none;scrollbar-width: none; }
.over_txt::-webkit-scrollbar{display: none;}
.over_txt span{display: block;white-space: nowrap;}
.tag{font-size: 13px;color: #00b2cd;display: inline-block;border:1px solid #00b2cd;border-radius: 5px;padding: 3px 10px;background: #fff;}
.tag_s{font-size: 11px;color: #00b2cd;display: inline-block;border:1px solid #00b2cd;border-radius: 5px;padding: 0 5px;background: #fff;}
.tag2{font-size: 15px;color: #fff;display: inline-block;background: #00b2cd url(../../images/arrow_y.svg) no-repeat top 10px left 10px;border-radius: 5px;padding: 4px 10px 4px 27px;}
.tag3{font-size: 12px;color: #fff;padding: 2px 5px 2px 21px;background: #008ECD url(../../images/ic_check.svg) no-repeat center left 7px;border-radius: 5px;margin-left: 9px;font-weight: 400;border: none;display:inline-block;}
.cancel_tag{font-size: 14px;background: #ddd;padding: 5px 0;}
button.cancel_tag{display: block;width: 100%;}
.input_btn .custom_input{width: calc(100% - 85px);}
.input_btn2 .custom_input{width: calc(100% - 170px);}
.input_btn .custom_input input{padding: 0 30px 0 10px !important;}
.input_btn .custom_input .btn_delt{position: absolute;width: 30px;height: 100%;right: 0;top: 0;font-size: 0;background: url(../../images/ic_close.svg) no-repeat center;background-size: 10px;}
.flex_between>.table_gray_txt{width: calc(100% - 210px);}
.table_gray_txt{font-size: 15px;color: #999;}
.table_gray_txt span{padding-left: 14px;position: relative;}
.table_gray_txt span::after{position: absolute;content: '';width: 1px;height: 15px;background: #ddd;top: 50%;left: 7px;transform: translateY(-50%);}
.table_gray_txt span:last-child{color: #333;font-weight: 600;}
.thumb_box{cursor: pointer;position: relative;text-align: center;}
.thumb_box::after{position: absolute;content: '';width: 20px;height: 20px;background: url(../../images/ic_full.png) no-repeat center;background-size: 100%; right: 10px;bottom: 10px;opacity: 0;transition: all .3s;}
.thumb_box:hover::after{opacity: 1;}
.thumb_box video{max-width: 100%;}
.thumb_box>img{max-height: 100%;}
.pc_wrap .page_sub{background: #f8f8f8;min-height: calc(100vh - 208px);margin-top: -100px;padding-top: 100px;}
.tab_ul li{font-size: 15px;font-weight: 500;text-align: center;color: #666;height: 45px;line-height: 45px;width: 296px;background: #fff;border: 1px solid #ddd;cursor: pointer;}
.tab_ul li.on{color: #fff;background: #666;border-color: #666;}
.dn{display: none;}
.Toastify{font-size: 15px;}
.arrow_down{background: url(../../images/arrow_down.svg) no-repeat center;height: 35px;width: 100%;}
.f_12{font-size: 12px;}
.f_13{font-size: 13px !important;}
.f_15{font-size: 15px !important;}
.none_data{text-align: center;font-size: 13px;padding: 30px 0;border-top: 1px solid #ddd;border-bottom: 1px solid #ddd;}
.text_input {border: none;}
.text_input input{background: none !important;}
.text_input.flex input{padding-right: 0;}
.blue_status{color: #fff;background: #008ecd;text-align: center;padding: 5px 10px;font-size: 13px;}
.tab_wrap .tab_cont{display: none;}
.tab_wrap .tab_cont.on{display: block;}
.over_hidden{overflow: hidden;}
.memo_txt_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: start; -ms-flex-align: start;-webkit-align-items: flex-start; align-items: flex-start;}
.memo_txt_box>*:last-child{width: calc(100% - 115px);}
.th_yellow{background: #F9F2D0 !important;}
.td_yellow{background: #FFFBE5 !important;}
.th_blue{background: #CDDFF9 !important;}
.td_blue{background: #DCEAFF !important;}
.th_green{background: #E5F6CC !important;}
.td_green{background: #F4FFE5 !important;}
.th_orange{background: #FAE0C8 !important;}
.td_orange{background: #FFF1E4 !important;}
.th_purple{background: #EBE0FD !important;}
.td_purple{background: #F6F1FF !important;}
.bg_yellow{background: #FFFBE2;}
.bg_blue{background: #D6E6FE;}
.bg_green{background: #F2FFE2;}
.bg_purple{background: #F5EFFE;}
.bg_orange{background: #FFEFE1;}
.over_scroll_box{overflow-y: hidden;overflow-x: auto;}
.w_1200px{width: 1200px;}

/* 회원가입/아이디,비번찾기 */
.page_form{min-height:calc(100vh - 308px);}
.page_form .top_banner{height: 300px;text-align: center;background: url(../../images/signup_banner.jpg) no-repeat center;background-size: cover;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: center; -ms-flex-pack: center;-webkit-justify-content: center; justify-content:center;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.page_form .top_banner h3{font-size: 40px;font-weight: 600;color: #fff;}
.page_form .top_banner p{font-size: 18px;color: #fff;}
.page_form>.cont, .page_form>.cont2{padding: 50px 0 100px;}
.pc_wrap .step_box{border: 1px solid #ddd;margin-bottom: 30px;}
.pc_wrap .step_box li{width: 33.333%;padding: 20px 70px;position: relative;color: #999999;}
.pc_wrap .step_box li::after{position: absolute;content: '';width: 1px;height: 40px;background: #ddd;top: 50%;right: 0;transform: translateY(-50%);opacity: 0.6;}
.pc_wrap .step_box li::before{position: absolute;content: '';width: calc(100% - 140px);height: 4px;background: #008ecd;bottom: 0;left: 70px;display: none;}
.pc_wrap .step_box li:last-child:after{display: none;}
.pc_wrap .step_box li p strong{font-weight: 600;padding-right: 12px;}
.pc_wrap .step_box li .img{width: 50px;height: 50px;background: url(../../images/ic_signup01.svg) no-repeat center;}
.pc_wrap .step_box li:nth-child(2) .img{background: url(../../images/ic_signup02.svg) no-repeat center;}
.pc_wrap .step_box li:nth-child(3) .img{background: url(../../images/ic_signup03.svg) no-repeat center;}
.pc_wrap .step_box li.on{color: #333;}
.pc_wrap .step_box li.on::before{display: block;}
.pc_wrap .step_box li.on p strong{color: #008ecd;}
.pc_wrap .step_box li.on .img{background: url(../../images/ic_signup01_on.svg) no-repeat center;}
.pc_wrap .step_box li:nth-child(2).on .img{background: url(../../images/ic_signup02_on.svg) no-repeat center;}
.pc_wrap .step_box li:nth-child(3).on .img{background: url(../../images/ic_signup03_on.svg) no-repeat center;}
.page_form>.cont .tit{font-size: 22px;font-weight: 600;}
.page_form>.cont .tit + .txt{font-size: 14px;color: #666;}
.page_form .gray_box{border-top: 1px solid #333;margin-top: 20px;background: #f8f8f8;padding: 40px 50px;}
.page_form .terms_box .scroll_wrap{border: 1px solid #ddd;padding: 20px;background: #fff;height: 140px;}
.page_form .terms_box .scroll_wrap p{font-size: 15px;color: #666;}
.form_ul>li{padding-bottom: 15px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; }
.form_ul>li .input_tit{font-size: 15px;font-weight: 500;width: 10%;padding-top: 10px;}
.form_ul>li .input_tit span{color: #008ecd;}
.form_ul>li>.box{width: 80%;}
.form_ul>li>.box>*{width: 50%;}
.form_ul>li>.box .custom_input,  .form_ul>li>.box .custom_select select{height: 45px;}
.form_ul>li>.box .custom_radio label .txt {font-size: 14px;}
.form_ul>li.check_li {margin-top: 35px;border-top: 1px solid #ddd;padding-top: 35px;}
.form_ul>li.check_li .input_tit{width: 40%;}
.cont2 .form_ul>li .input_tit{width: 20%;}
.cont2 .form_ul>li>.box>*{width: 100%;}
.page_form .big_tit{font-size: 30px;font-weight: 600;}
.page_form .sub_tit{font-size: 14px;color: #999;}
.page_form .line_box{border: 5px solid #eee;padding: 30px 0;width: 440px;margin: 50px auto 0;}
.page_form .line_box .img{width: 70px;height: 70px;background: #f8f8f8 url(../../images/check_img.svg) no-repeat center;border-radius: 50%;margin: 0 auto 20px;}
.page_form .line_box .box{background: #f8f8f8;padding: 14px 0;margin-top: 40px;}
.page_form .btn_box button,  .page_form .btn_box a{width: 160px;padding: 0;}


/* 온라인경매 */
 .cont_wrap{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;padding-bottom: 120px;}
 .cont_wrap .left_cont{width: 260px;position: relative;}
 .cont_wrap .left_cont .btn_toggle{position: absolute;width: 44px;height: 44px;background: #fff url(../../images/toggle_arrow.svg) no-repeat center right 11px;box-shadow:0 2px 10px rgba(151,151,151,0.25);font-size: 0;border-radius: 50%;top: 30px;right: -22px;z-index: 1;}
 .cont_wrap .left_cont .user_box{background: #fff;box-shadow:0 2px 10px rgba(151,151,151,0.25);padding: 30px;position: relative;margin-bottom: 17px;}
 .cont_wrap .left_cont .user_box::after{position: absolute;content: '';width: 30px;height: 80px;background: #fff;right: 0;top: 0;z-index: 2;}
 .cont_wrap .left_cont .user_box .top_box{padding-bottom: 6px;border-bottom: 1px solid #ddd;margin-bottom: 8px;}
 .cont_wrap .left_cont .user_box .top_box .tag3{font-size: 10px;}
/*  .cont_wrap .left_cont .user_box .gray_box{padding: 10px;font-size: 12px;color: #666;text-align: center;background: #f8f8f8;margin: 12px 0 10px;} */
 .cont_wrap .left_cont .register_box{margin-bottom: 17px;}
 .cont_wrap .left_cont .register_box a{display: block;padding: 23px 30px;background: #fff;position: relative;min-height: 140px;}
 .cont_wrap .left_cont .register_box a::after{position: absolute;content: '';width: 100%;height: 100%;top: 0;left: 0; border: 5px solid #00b2cd;transition: all .1s;box-sizing: border-box;}
 .cont_wrap .left_cont .register_box .txt{font-size: 20px;font-weight: 600;color: #00b2cd;}
 .cont_wrap .left_cont .register_box .txt2{font-size: 15px;color: #666;padding-top: 10px;}
 .cont_wrap .left_cont .register_box img{position: absolute;top: 40px;right:30px;transition: ease-in .2s;}
 .cont_wrap .left_cont .register_box a:hover::after{border-width: 8px;}
 .cont_wrap .left_cont .register_box a:hover img{top: 35px;}
 .cont_wrap .left_cont .register_box a.auction img{top: 18px;}
 .cont_wrap .left_cont .register_box a.auction:hover img{top: 13px;}
 .cont_wrap .left_cont .link_box{margin-bottom: 17px;}
 .cont_wrap .left_cont .link_box li a{display: block; padding: 17px 5px 17px 77px;font-size: 17px;color: #222;background: #fff url(../../images/ic_link01.svg) no-repeat center left 32px;transition: all .3s;}
 .cont_wrap .left_cont .link_box li.link2 a{background: #fff url(../../images/ic_link02.svg) no-repeat center left 32px;}
 .cont_wrap .left_cont .link_box li.link3 a{background: #fff url(../../images/ic_link03.svg) no-repeat center left 32px;}
 .cont_wrap .left_cont .link_box li a:hover{color: #008ecd;background:#f3f9ff url(../../images/ic_link01_on.svg) no-repeat center left 32px;}
 .cont_wrap .left_cont .link_box li.link2 a:hover{background:#f3f9ff url(../../images/ic_link02_on.svg) no-repeat center left 32px;}
 .cont_wrap .left_cont .link_box li.link3 a:hover{background:#f3f9ff url(../../images/ic_link03_on.svg) no-repeat center left 32px;}
 .cont_wrap .left_cont .link_box li a.on{color: #008ecd;background:#f3f9ff url(../../images/ic_link01_on.svg) no-repeat center left 32px;}
 .cont_wrap .left_cont .link_box li.link2 a.on{background:#f3f9ff url(../../images/ic_link02_on.svg) no-repeat center left 32px;}
 .cont_wrap .left_cont .link_box li.link3 a.on{background:#f3f9ff url(../../images/ic_link03_on.svg) no-repeat center left 32px;}
 .cont_wrap .left_cont .down_box{border: 5px solid #ddd;background: #fff;padding: 25px;}
 .cont_wrap .left_cont .down_box p{font-size: 17px;font-weight: 500;padding-bottom: 15px;}
 .cont_wrap .left_cont .down_box a{display: inline-block;font-size: 15px;color: #222;padding: 8px 44px 8px 20px;background: #f8f8f8 url(../../images/ic_down2.svg) no-repeat center right 20px;border: 1px solid #ddd;border-radius: 50px;margin-bottom: 10px;}
 .cont_wrap .left_cont .down_box a:last-child{margin-bottom: 0 !important;}
 .cont_wrap .right_cont{width: calc(100% - 290px);background: #fff;box-shadow:0 2px 10px rgba(151,151,151,0.25);}
 .cont_wrap .right_cont .inner_box{padding: 30px;}
 .cont_wrap .right_cont .inner_box.line_top{border-top: 10px solid #f8f8f8;}
 .cont_wrap .right_cont .top_tit{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: end; -ms-flex-align: end;-webkit-align-items: flex-end; align-items: flex-end;padding-bottom: 14px;}
 .cont_wrap .right_cont .top_tit .tit{font-size: 22px;font-weight: 600;padding-left: 19px;background: url(../../images/leaf_img.svg) no-repeat left top 12px;}
 .cont_wrap .right_cont .top_tit .menu_list{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;}
 .cont_wrap .right_cont .top_tit .menu_list li{font-size: 14px;color: #999;padding-right: 24px;background: url(../../images/right_arrow.svg) no-repeat center right 10px;}
 .cont_wrap .right_cont .top_tit .menu_list li:last-child{padding-right: 0;background: none;color: #008ecd;}
 .cont_wrap.fold .left_cont{width: 90px;}
 .cont_wrap.fold .left_cont .btn_toggle{background: #fff url(../../images/toggle_arrow2.svg) no-repeat center right 11px;top: 18px;}
 .cont_wrap.fold .left_cont .user_box{padding: 0;height: 160px;margin-bottom: 10px;}
 .cont_wrap.fold .left_cont .user_box .btn_type3{background: #fff url(../../images/ic_list.svg) no-repeat center top 10px;padding: 41px 0 0;font-size: 12px;color: #222;border: none;height: auto;line-height: 1.3;position: relative;z-index: 3;margin: 0;height: 80px;border-bottom: 1px dashed #ddd;}
 .cont_wrap.fold .left_cont .user_box .btn_type2{background: #fff url(../../images/ic_logout.svg) no-repeat center top 10px;padding: 41px 0 0;font-size: 12px;color: #222;border: none;height: auto;line-height: 1.3;position: relative;z-index: 3;height: 80px;}
 .cont_wrap.fold .left_cont .register_box{margin-bottom: 10px;}
 .cont_wrap.fold .left_cont .register_box a{padding: 63px 0 0;height: 100px;text-align: center;background:#fff url(../../images/ic_register.svg) no-repeat center top 26px;background-size: 25px;min-height: auto;}
 .cont_wrap.fold .left_cont .register_box .txt{font-size: 12px;}
 .cont_wrap.fold .left_cont .register_box img{display: none;}
 .cont_wrap.fold .left_cont .link_box{margin-bottom: 10px;}
 .cont_wrap.fold .left_cont .link_box li{border-bottom: 1px dashed #ddd;}
 .cont_wrap.fold .left_cont .link_box li a{height: 100px;padding: 50px 10px 15px;text-align: center;background-position-y:15px !important;line-height: 1;}
 .cont_wrap.fold .left_cont .link_box li a span{opacity: 0;animation: txtShow 0.3s 0.3s forwards ease;font-size: 12px;}
@keyframes txtShow {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
 .cont_wrap.fold .left_cont .down_box{box-shadow:0 2px 10px rgba(151,151,151,0.25);padding: 8px 0 12px;text-align: center;}
 .cont_wrap.fold .left_cont .down_box p{font-size: 13px;padding-bottom: 12px;border-bottom: 1px dashed #ddd;margin-bottom: 10px;}
 .cont_wrap.fold .left_cont .down_box a{font-size: 12px;padding: 5px 10px;background: #f8f8f8;margin-bottom: 5px;}
 .cont_wrap.fold .right_cont{width: calc(100% - 120px);}
/* 경매사 - 입하작업,입찰판매관리 */
 .cont_wrap2{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;padding-bottom: 80px;}
 .cont_wrap2 .left_cont{width: 300px;}
 .cont_wrap2 .left_cont .top_box{padding: 20px 25px 30px;background: #fff;border:1px solid #ddd;}
 .cont_wrap2 .left_cont .top_box .top_tit{font-size: 20px;font-weight: 600;padding-left: 19px;background: url(../../images/leaf_img.svg) no-repeat left top 9px;margin-bottom: 20px;}
 .cont_wrap2 .left_cont .top_box .box_ul>li{padding-bottom: 20px;}
 .cont_wrap2 .left_cont .top_box .box_ul>li:last-child{padding-bottom: 0;}
 .cont_wrap2 .left_cont .top_box .box_ul>li>.txt{font-size: 14px;color: #666;padding-bottom: 5px;}
 .cont_wrap2 .left_cont .bottom_box .scroll_wrap{height: 500px; background: #dbdbdb;}
 .cont_wrap2 .right_cont{width: calc(100% - 310px);}
 .cont_wrap2 .right_cont .top_box li{margin-right: 9px;border: 1px solid #222;padding: 10px;text-align: center;min-width: 170px;font-size: 15px;font-weight: 500;background: #fff;cursor: default;}
 .cont_wrap2 .right_cont .top_box li:last-child{margin-right: 0;}
 .cont_wrap2 .right_cont .top_box li:nth-child(n+2){cursor: pointer;}
 .cont_wrap2 .right_cont .top_box li span{padding-left: 28px;background: url(../../images/top_tab_ic01.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:nth-child(2) span{background: url(../../images/top_tab_ic02.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:nth-child(3) span{background: url(../../images/top_tab_ic03.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:nth-child(4) span{background: url(../../images/top_tab_ic04.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li.on{color: #008ecd;border-color: #008ecd;}
 .cont_wrap2 .right_cont .top_box li.on span{background: url(../../images/top_tab_ic01_on.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:nth-child(2).on span{background: url(../../images/top_tab_ic02_on.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:nth-child(3).on span{background: url(../../images/top_tab_ic03_on.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:nth-child(4).on span{background: url(../../images/top_tab_ic04_on.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:hover{color: #008ecd;border-color: #008ecd;}
 .cont_wrap2 .right_cont .top_box li:hover span{background: url(../../images/top_tab_ic01_on.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:nth-child(2):hover span{background: url(../../images/top_tab_ic02_on.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:nth-child(3):hover span{background: url(../../images/top_tab_ic03_on.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .top_box li:nth-child(4):hover span{background: url(../../images/top_tab_ic04_on.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .bottom_box>.btn_box .custom_table{width: calc(100% - 300px);}
 .cont_wrap2 .right_cont .bottom_box>.btn_box button{width: 130px;}
 .cont_wrap2 .right_cont .bottom_box>.btn_box .btn_s span{position: relative;padding-left: 19px;background: url(../../images/btn_check.svg) no-repeat center left;}
 .cont_wrap2 .right_cont .bottom_box .many_btn_box button{padding:0 15px;font-size: 14px;color: #222;font-weight: 500;}
 .cont_wrap2 .right_cont .bottom_box .table_btn_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; }
 .cont_wrap2 .right_cont .bottom_box .table_btn_box .custom_table{width: calc(100% - 74px);}
 .cont_wrap2 .right_cont .bottom_box .table_btn_box .btn_box .btn3_s2{display: block;width: 74px;height: 100%;line-height: 1.6;}
 .cont_wrap2 .right_cont .gray_box>.box{width: calc(50% - 5px);background: #ededed;border: 1px solid #ddd;padding: 10px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; }
 .cont_wrap2 .right_cont .gray_box>.box:nth-child(2){padding: 16px 10px;}
 .cont_wrap2 .right_cont .gray_box>.box .img_ul li{width: 84px;margin-right: 5px;border: 1px solid #ddd;}
 .cont_wrap2 .right_cont .gray_box>.box .img_ul li .img{width: 100%;height: 60px;background: #fff url(../../images/img_none.svg) no-repeat center;overflow: hidden;text-align: center;}
 .cont_wrap2 .right_cont .gray_box>.box .img_ul li:last-child .img{background: #fff url(../../images/video_upload.svg) no-repeat center;background-size: 25px;overflow: hidden;}
 .cont_wrap2 .right_cont .gray_box>.box .img_ul li .img img{max-width: 100%;height: 100%;cursor: pointer;}
 .cont_wrap2 .right_cont .gray_box>.box .img_ul li .img video{max-width: 100%;height: 100%;cursor: pointer;}
 .cont_wrap2 .right_cont .gray_box>.box .img_ul li p{text-align: center;font-size: 13px;color: #fff;font-weight: 500;padding: 3px;background: #777777;}
 .cont_wrap2 .right_cont .gray_box>.box .txt{font-size: 11px;color: #666;}
 .cont_wrap2 .right_cont .gray_box>.box .input_ul {-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;width: calc(100% - 74px);padding-right: 10px;}
 .cont_wrap2 .right_cont .gray_box>.box .input_ul li{width:calc(50% - 5px);}
 .cont_wrap2 .right_cont .gray_box>.box .input_ul li p{width: 55px;font-size: 13px;font-weight: 500;}
 .cont_wrap2 .right_cont .gray_box>.box .input_ul li .custom_input{width: calc(100% - 55px);}
 .cont_wrap2 .right_cont .gray_box>.box .input_ul li:last-child{width: 100%;padding-top: 5px;}
 .cont_wrap2 .right_cont .gray_box>.box .btn_box{width: 74px;}
 .cont_wrap2 .right_cont .gray_box>.box .btn_box button{display: block;width: 74px;height: 100%;line-height: 1.6;background: #555;border-color: #555;}
 .bid_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;}
 .bid_box>.box{width: calc(50% - 5px);}
 .bid_box .btn_box button{width: 137px;height: 47px;color: #fff;background: #0f6186;font-size: 14px;font-weight: 500;}
 .bid_box .btn_box button span{background: url(../../images/ic_o.svg) no-repeat center left;padding-left: 23px;}
 .bid_box .btn_box button.btn_no{background: #668e9f;}
 .bid_box .btn_box button.btn_no span{background: url(../../images/ic_x.svg) no-repeat center left;}
 .bid_box .btn_box button.btn_no2{background: #858C8F;}
 .bid_box .btn_box button.btn_no2 span{background: url(../../images/ic_no.svg) no-repeat center left;}
 .bid_box .btn_box button.btn_cancel{background: #EDEDED;color: #999;border:1px solid #ddd;}
 .bid_box .btn_box button.btn_cancel span{background: url(../../images/ic_cancel.svg) no-repeat center left;}
 .bid_box>.box .scroll_wrap{max-height: 150px;}
 .bid_box>.box:nth-child(2) .scroll_wrap{max-height: 202px;min-height: calc(100% - 31px);}

/* drop_box */
 .drop_wrap{position: relative;}
 .drop_wrap>.custom_input input{cursor: default;padding:0 10px;font-size: 13px;}
 .drop_wrap .dim{position: fixed;width: 200%;height: 200%;top: 50%;left: 50%;transform: translate(-50%,-50%);background: transparent;z-index:-1;}
 .drop_box {position: absolute;width: 100%;top: 40px;left: 0;border: 1px solid #ddd;background: #fff;z-index: 1;min-width: 110px;max-height: 150px;overflow-x: hidden;overflow-y: auto;}
 .drop_box.loca {width: 200px;}
 .drop_box2{top:30px;}
 .drop_box .list_ul>li{font-size: 13px;padding: 10px;border-top: 1px solid #ddd;text-align: left;cursor: default;color: #666;}
 .drop_box .list_ul>li:first-child{border-top: none;}
 .drop_box .list_ul>li:hover{background: #e3f0ff;color: #222;}
 .drop_box .list_ul>li.none, .drop_box .list_ul2>li.none{text-align: center;padding: 30px 0;}
 .drop_box .list_ul>li.none:hover, .drop_box .list_ul2>li.none:hover{color: #666;background: #fff;}
 .drop_box .list_ul2>li .txt{font-size: 13px;font-weight: 600;padding: 4px 10px;text-align: left;}
 .drop_box .list_ul2>li .list_ul>li{border-top: none;}
 .drop_box .new_box{padding:10px;}
 .drop_box .new_box p{font-size: 13px;text-align: left;padding-bottom: 5px;}
 .drop_box .new_box .custom_input2{width: calc(100% - 67px);}
 .drop_box .new_box .btn_type3{padding: 0;width: 60px;height: 40px;line-height: 39px;font-weight: 400;}

/* address_box */
 .address_box>div:nth-child(2){width: calc(100% - 212px);}
 .address_box .btn_s3{padding: 0 10px;}

/* search_box */
 .search_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;background: #f8f8f8;padding: 20px;}
 .search_box>li{width: 50%;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;-ms-flex-wrap: wrap;flex-wrap: wrap;}
 .search_box>li .txt{font-size: 15px;font-weight: 500;padding-right: 10px;}
 .search_box>li .custom_select{width: 180px;}
 .search_box>li .sel_box .custom_select{width: auto;}
 .search_box>li.item_li .txt + div{width: calc(100% - 40px);}
 .search_box>li.item_li .txt + div>div:first-child{width: calc(55% - 5px);}
 .search_box>li.item_li .txt + div>div:nth-child(2){width: calc(45% - 5px);}
 .search_box>li.item_li .drop_wrap{width: calc(60% - 5px);}
 .search_box2>li{width: 100%;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -ms-flex-wrap: nowrap;flex-wrap: nowrap;}
 .search_box2>li .txt{padding-right: 0;width: 10%;}
 .search_box2>li>.box{width: 90%;}

/* custom_table */
 .custom_table{border-top: 1px solid #222;}
 .custom_table th{font-size: 15px;font-weight: 400;color: #222;text-align: center;padding: 9px;background: #f8f8f8;border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;line-height: 1.2;}
 .custom_table tr>*:first-child{border-left: none;}
 .custom_table tbody td{font-size: 15px;color: #666;text-align: center;padding: 9px;background: #fff;border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;}
 .custom_table tbody .total_tr td{background: #f8f8f8;color: #222;}
 .custom_table .th_link{padding: 0;}
 .custom_table .th_link span{color: #222;display: inline-block;padding: 9px;background: url(../../images/ic_link.png) no-repeat center right;padding-right: 22px;}
 .custom_table .tr_blue td{background: #e2f1f8;}
 .custom_table.wide_table th{text-align: left;padding: 16px 9px;}
 .custom_table.wide_table td{padding: 16px 9px;}
 .custom_table.input_table td{padding: 8px 9px;}
 .custom_table.s_table th{font-size: 14px;background: #ededed;}
 .custom_table.s_table td{font-size: 14px;color: #222;}
 .custom_table.narrow_td td{padding: 9px 2px;}
 .custom_table.gray_table th{background: #ededed;text-align: left;font-size: 14px;}
 .custom_table.gray_table td{font-size: 14px;text-align: left;color: #222;}
 .custom_table.gray_table tr.on td{background: #e3f0ff;}
 .custom_table.gray_table2 th{background: #EDEDED;font-size: 15px;border-bottom: 1px solid #222;}
 .custom_table.gray_table2 td{font-size: 15px;color: #222;}
 .custom_table.gray_table2 .total_tr td{background: #EDEDED;color: #222;border-bottom: 1px solid #222;}
 .custom_table.gray_table2 tbody tr:nth-last-child(2) td{border-bottom: 1px solid #222;}
 .custom_table2{border-top: 1px solid #222;}
 .custom_table2 tbody th{font-size: 15px;color: #222;font-weight: 400;background: #e3f0ff;border-bottom: 1px solid #ddd;padding: 16px 9px;text-align: left;}
 .custom_table2 tbody th.th_gray{background: #f8f8f8;}
 .custom_table2 tbody th.th_gray2{background: #ededed;}
 .custom_table2 tbody th span{color: #008ecd;}
 .custom_table2 tbody td{font-size: 15px;color: #222;background: #fff;border-bottom: 1px solid #ddd;padding: 16px 9px;text-align: left;}
 .custom_table2.input_table td{padding: 8px 9px;}
 .custom_table3{border-top: 1px solid #222;}
 .custom_table3 th{font-size: 14px;font-weight: 400;color: #222;text-align: center;padding: 16px 9px;background: #ededed;border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;line-height: 1.2;}
 .custom_table3 tr>*:first-child{border-left: none;}
 .custom_table3 tbody td{font-size: 14px;color: #222;text-align: center;padding: 16px 9px;background: #fff;border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;}
 .custom_table3.input_table td{padding:9px;}
 .custom_table4{border-top: 1px solid #222;}
 .custom_table4 th{font-size: 14px;font-weight: 500;color: #222;text-align: center;padding: 7px;background: #ededed;border-bottom: 1px solid #fff;border-left: 1px solid #fff;}
 .custom_table4 td{color: #222;text-align: center;background: #feffcd;font-size: 14px;padding: 7px;border-bottom: 1px solid #e2e2e2;}
 .custom_table4 .tr_excess td{background: #ffeaea;}
 .custom_table4 .tr_white td{background: #fff;}
 .custom_table4 .tr_red td{color: #fff;text-align: center;background: #e48060;border-bottom: 1px solid #fff;border-left: 1px solid #fff;}
 .custom_table4 .tr_red2 td{color: #fff;text-align: center;background: #e48060b0;border-bottom: 1px solid #fff;border-left: 1px solid #fff;}
 .custom_table4 .tr_current td{color: #ffe500;text-align: center;background: #5e7f8d;border-bottom: 1px solid #fff;border-left: 1px solid #fff;}
 .custom_table4 .tr_blue td{color: #222;text-align: center;background: #e2f1f8;border-bottom: 1px solid #fff;border-left: 1px solid #fff;}
 .custom_table4 tr>*:first-child{border-left: none !important;}
 .custom_table4 tbody tr:last-child td{border-bottom: 1px solid #ddd;}
 .custom_table5{border-top: 1px solid #222;}
 .custom_table5 th{font-size: 14px;font-weight: 500;color: #222;text-align: center;padding: 7px;background: #ededed;border-bottom: 1px solid #ddd;}
 .custom_table5 td{font-size: 13px;font-weight: 400;color: #222;text-align: center;padding: 7px 5px;position: relative;background: #fff;}
 .custom_table5 td::after{position: absolute;content: '';width: 1px;height: 12px;background: #ddd;right: 1px;top: 50%;transform: translateY(-50%);}
 .custom_table5 td:last-child:after{display: none;}
 .custom_table5 .tr_blue td{background: #dceaff;}
 .custom_table5 .tr_yellow td{background: #F4FFE5;}
 .custom_table5 .tr_orange td{background: #FFECDA;}
 .custom_table5 .tr_green td{background: #5e7f8d;color: #ffe500;font-weight: 600;}
 .custom_table6{border-top: 1px solid #222;}
 .custom_table6 th{font-size: 14px;font-weight: 500;color: #222;text-align: center;padding: 7px;background: #ededed;border-bottom: 1px solid #ddd;border-right: 1px solid #ddd;}
 .custom_table6 tr>*:last-child{border-right: none;}
 .custom_table6 td{font-size: 14px;font-weight: 400;color: #222;text-align: center;padding: 7px;border-bottom: 1px solid #e2e2e2;background: #fff;}
 .custom_table6 tr.on td{background: #a2f5b4;}
 .border_box .custom_table4, .border_box .custom_table5{border-top: none;}
 table td .btn2_s2, table td .btn2_s{font-size: 13px;}
 table .sky_bg{background: #00b2cd !important;color: #fff !important;}

/*파일업로드*/
 .file_up_wrap{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;position: relative;min-height: 160px;-webkit-box-pack: center; -ms-flex-pack: center;-webkit-justify-content: center; justify-content: center;}
 .file_up_wrap label {position: absolute;top: 0;left: 0; cursor: pointer;width: 100%;height: 100%; background: url(../../images/img_upload.svg) no-repeat center top 50px;font-size: 13px;color: #999;padding-top: 110px;z-index: 1;}
 .file_up_wrap label.video_up{background: url(../../images/video_upload.svg) no-repeat center top 50px;}
 .file_up_wrap input[type="file"] {position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0;}
 .file_up_wrap .btn_delt{position: absolute;font-size: 0;width: 30px;height: 30px;top: 0;right: 0;background: url(../../images/ic_close.svg) no-repeat left bottom;z-index: 2;display: none;}
 .file_up_wrap.on label{font-size: 0;background: none;}
 .file_up_wrap.on .btn_delt{display: block;}
 .file_up_wrap .preview {max-height: 400px;overflow: hidden;}
 .file_up_wrap .preview video{max-width: 100%;}

/* 온라인경매 페이지 */
.auction_wrap{height: calc(100vh - 100px);}
.auction_wrap2{height: auto;}
.auction_wrap>.cont{height: 100%;}
.auction_wrap .top_cont{padding: 10px 20px;background: #fff;border: 1px solid #ddd;}
.auction_wrap .top_cont .tit{font-size: 22px;font-weight: 600;padding-left: 19px;background: url(../../images/leaf_img.svg) no-repeat left top 12px;}
.auction_wrap .top_cont .inner_ul{width: calc(100% - 240px);}
/* .auction_wrap .top_cont .inner_ul>li{width: 230px;padding-left: 0;}
.auction_wrap .top_cont .inner_ul>li:nth-child(n+2){width: calc(calc(100% - 230px)/2);padding-left: 20px;} */
.auction_wrap .top_cont .inner_ul>li{width: calc(calc(100% - 230px)/2);padding-left: 20px;}
.auction_wrap .top_cont .inner_ul>li:last-child{width: auto;}
.connect_box li{font-size: 14px;font-weight: 500;color: #666;margin-right: 16px;}
.connect_box li:last-child{margin-right: 0;}
.connect_box li .circle{display: block;width: 10px;height: 10px;border-radius: 50%;margin-left: 8px;background: #00C62B;}
.connect_box li .circle.out{background: #FF0000;}
.auction_wrap .top_cont .inner_ul .custom_select{width: 230px;}
.auction_wrap .top_cont .inner_ul .box{width: calc(100% - 78px);}
.auction_wrap .top_cont .inner_ul .box .over_txt{width: calc(100% - 80px);}
.auction_wrap .top_cont .inner_ul2{width: calc(100% - 145px);}
.auction_wrap .top_cont .inner_ul2 li .date_input2{width: 320px;}
.auction_wrap .top_cont .inner_ul2 li .date_input2 .custom_input{width: calc(50% - 10px);}
.auction_wrap .top_cont .inner_ul2 li .date_input2::after{left: 50%;transform: translateX(-50%);}
.auction_wrap .top_cont .inner_ul2 li:nth-child(2) .custom_select{width: 120px;}
.auction_wrap .top_cont .inner_ul2 li:nth-child(2) .custom_select select{height: 36px;}
.auction_wrap .top_cont .inner_ul2 .num_box>div{position: relative;}
.auction_wrap .top_cont .inner_ul2 .num_box>div::after{position: absolute;content: '~';width: 18px;height: 100%;top: 0;left:70px;text-align: center;font-size: 13px;line-height: 36px;color: #333;}
.auction_wrap .top_cont .inner_ul2 .num_box .custom_input{width:70px;height: 36px;margin-right: 18px;}
.auction_wrap .top_cont .inner_ul2 .num_box .custom_input:nth-child(2){margin-right: 0;}
.auction_wrap .top_cont .inner_ul2 .num_box .custom_input input{padding: 0 10px;}
.auction_wrap .top_cont .inner_ul2 .btn_s{width: 123px;height: 36px;line-height: 36px;margin-left: 5px;}
.auction_wrap .top_cont .inner_ul2 .btn_s2{width: 123px;height: 36px;line-height: 36px;}
.auction_wrap .top_cont .inner_ul3{width: calc(100% - 180px);}
.auction_wrap .top_cont .txt{font-size: 14px;color: #666;padding-right: 10px;}
.auction_wrap .bar_box{width: 120px;height: 3px;background: #ddd;position: relative;}
.auction_wrap .bar_box .bar{position: absolute;height: 100%;top: 0;left: 0;background: linear-gradient(to right, #00b2cd, #008ecd);}
.auction_cont {padding: 20px 0 40px;-webkit-box-align: start; -ms-flex-align: start;-webkit-align-items: flex-start; align-items: flex-start;height: calc(100% - 62px);}
.auction_cont .left_cont{width: calc(100% - 320px);height: 100%;}
.auction_cont .left_cont>.border_box{height:calc(100% - 280px);}
.auction_cont .left_cont>.border_box .scroll_wrap{height: calc(100% - 37px);border-bottom: 1px solid #ddd;background: #fff;}
.border_box{border-top: 1px solid #222; border-right:1px solid #ddd;border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;}
.auction_cont .right_cont>.border_box{height: calc(100% - 295px);}
.auction_cont .right_cont>.border_box .scroll_wrap{height: calc(100% - 125px);background: #fff;}
.auction_cont .memo_txt{color: #fff;padding: 10px;background: #00b2cd;font-weight: 600;}
.auction_cont .soldout_txt{color: #fff;background: #008ecd url(../../images/ic_soldout.svg) no-repeat center left 18px;text-align: center;padding: 9px 18px 9px 34px;}
.auction_cont .price_box{background: #00b2cd;padding: 0;}
.auction_cont .price_box p{width: 100px;padding: 9px;color: #fff;border-right: 1px solid #fff;}
.auction_cont .price_box p:nth-child(2){width: calc(100% - 100px);border-right: none;}
.auction_cont .right_cont{width: 300px;height: 100%;}
.auction_cont .btn_ul li{width: 25%;border-bottom: 1px solid #ddd;border-right: 1px solid #ddd;}
.auction_cont .btn_ul li:last-child{border-right: none;}
.auction_cont .btn_ul li button{width: 100%;height: 35px;text-align: center;font-size: 15px;font-weight: 500;background: #ededed;}
.auction_cont .btn_ul li.on button{color: #fff;background:#666;}
.auction_cont .btn_ul2 li{width: 50%;}
.auction_cont .btn_ul2 li:first-child{padding: 5px;background: #fff;}
.auction_cont .btn_ul2 li:first-child .custom_input{height: 35px;}
.auction_cont .btn_ul2 li button{background: #4e6975;color: #fff;text-align: center;width: 100%;height: 45px;}
.auction_cont .btn_ul2 li:nth-child(3) button{background: #668e9f;}
.auction_cont .btn_ul2 li:nth-child(3) button span{background: url(../../images/btn_cancel.svg) no-repeat center left;padding-left: 18px;}
.auction_cont .btn_ul2 li:nth-child(4) button{background: #0f6186;}
.auction_cont .btn_ul2 li:nth-child(4) button span{background: url(../../images/btn_check.svg) no-repeat center left;padding-left: 20px;}
.auction_cont .btn_ul3{background:#858c8f;}
.auction_cont .btn_ul3 li{width: 33.333%;}
.auction_cont .btn_ul3 li button{width: 100%;height: 40px;text-align: center;font-size: 14px;font-weight: 500;color: #fff;background: #0f6186;}
.auction_cont .btn_ul3 li:nth-child(2) button{background: #668e9f;}
.auction_cont .btn_ul3 li:nth-child(3) button{background: #858c8f;}
.auction_cont .right_cont .user_box{position: relative;}
.auction_cont .right_cont .user_box>.box{background: #1f2a8f;color: #fff;font-weight: 500;font-size: 13px;}
.auction_cont .right_cont .user_box>.box button{color: #fff;}
.auction_cont .right_cont .user_box>.box .ic_btn{height: 40px;font-size: 12px;font-weight: 500;color: #fff;padding-left: 21px;background: url(../../images/ic_mobile.svg) no-repeat center left;}
.auction_cont .right_cont .user_box>.box .ic_btn:nth-of-type(2){background: url(../../images/ic_pc.svg) no-repeat center left;}
.auction_cont .right_cont .user_box>.box .ic_btn span{color: #ffe500;padding-left: 5px;font-weight: 600;}
.auction_cont .right_cont .user_box>.txt_ul{padding: 5px 0;}
.auction_cont .right_cont .user_box>.txt_ul li{background: #e5f2fb;border: 2px solid #384145;width: calc(60% - 2.5px);text-align: center;padding: 5px;font-size: 16px;color: #384145;font-weight: 800;}
.auction_cont .right_cont .user_box>.txt_ul li:nth-child(2){width: calc(40% - 2.5px);}
.auction_cont .message_wrap{position: relative;}
.auction_cont .message_wrap .top_box {border: 1px solid #ddd;border-top-color: #222;}
.auction_cont .message_wrap .top_box .scroll_wrap{background: #ededed;padding: 20px 8px 0 15px;height: 230px;}
.auction_cont .message_wrap .top_box .box{padding-bottom: 20px;}
.auction_cont .message_wrap .top_box .box>li{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: end; -ms-flex-align: end;-webkit-align-items: flex-end; align-items: flex-end;padding-bottom: 15px;}
.auction_cont .message_wrap .top_box .box .txt{font-size: 14px;color: #444;max-width:calc(100% - 30px);padding: 8px 14px;background: #fffce3;border-radius: 0 20px 20px 20px;}
.auction_cont .message_wrap .top_box .box .txt strong{font-size: 12px;display: block;}
.auction_cont .message_wrap .top_box .box .time{font-size: 9px;color: #999;padding-left: 5px;}
.auction_cont .message_wrap .top_box .box>li.send{-webkit-box-pack: end; -ms-flex-pack: end;-webkit-justify-content: flex-end; justify-content: flex-end;}
.auction_cont .message_wrap .top_box .box .send .time{padding: 0 5px 0 0;}
.auction_cont .message_wrap .top_box .box .send .txt{background: #d5e9fb;border-radius: 20px 0 20px 20px;}
.auction_cont .message_wrap .bottom_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; }
.auction_cont .message_wrap .bottom_box .custom_input{height: 44px;width: calc(100% - 64px);}
.auction_cont .message_wrap .bottom_box .btn_s{width: 64px;height: 44px;}
.auction_cont .message_wrap .bottom_box2 .custom_input{height: 34px;}
.auction_cont .message_wrap .bottom_box2 .custom_input input{padding: 0 10px;font-size: 12px;}
.auction_cont .message_wrap .bottom_box2 .custom_input input::-webkit-input-placeholder {font-size: 12px;} 
.auction_cont .message_wrap .bottom_box2 .custom_input input:-moz-placeholder {font-size: 12px;} 
.auction_cont .message_wrap .bottom_box2 .custom_input input::-moz-placeholder {font-size: 12px;} 
.auction_cont .message_wrap .bottom_box2 .custom_input input:-ms-input-placeholder {font-size: 12px;}
.auction_cont .message_wrap .bottom_box2 button{height: 34px;line-height: 34px;font-size: 12px;}
.auction_cont .message_wrap .bottom_box2 .box{padding: 5px 0;}
.auction_cont .message_wrap .bottom_box2 .box .custom_input{width: 100px;}
.auction_cont .message_wrap .bottom_box2 .box .btn_s3{width: calc(100% - 105px);}
.auction_cont .message_wrap .bottom_box2 .box2 .custom_input{width: calc(100% - 75px);}
.auction_cont .message_wrap .bottom_box2 .box2 .btn_s{width: 70px;}
.auction_cont2 .left_cont>.border_box{height: calc(100% - 448px);}
.auction_cont2 .left_cont>.border_box .scroll_wrap{height: 100%;border-bottom: none;}
.auction_cont2 .right_cont>.top_box{height: calc(100% - 330px);}
.auction_cont2 .right_cont>.top_box .table_box{height: calc(100% - 88px);}
.auction_cont2 .right_cont>.top_box .table_box .border_box:nth-child(1){height: calc(100% - 132px);background: #fff;}
.auction_cont2 .right_cont>.top_box .table_box .border_box:nth-child(1) .scroll_wrap{height: calc(100% - 40px);background: #fff;}
.auction_cont2 .right_cont>.top_box .table_box .border_box:nth-child(2){height: 120px;}

/* 온라인경매 - 접속자 팝업 */
.user_pop {position: absolute;z-index: 2000;}
.user_pop .dim{position: fixed;width: 100%;height: 100%;top: 0;left: 0;background: rgba(0,0,0,0.5);}
.user_pop .inner_cont{position: relative;z-index: 1; width: 270px;border: 1px solid #008ecd;}
.user_pop .inner_cont .tit{font-size: 15px;font-weight: 500;color: #fff;padding: 10px;background: #008ecd;}
.user_pop .inner_cont .scroll_wrap{max-height: 204px;background: #fff;}
.user_pop .inner_cont .list_ul>li{width: 50%;font-size: 12px;color: #666;padding: 7px 5px 8px 30px;background: url(../../images/ic_pc_g.svg) no-repeat center left 5px;cursor: default;position: relative;}
.user_pop .inner_cont .list_ul>li.on{color: #333;font-weight: 600;background: #bff9b5 url(../../images/ic_pc_b.svg) no-repeat center left 5px;}
.user_pop .inner_cont .list_ul2>li{background: url(../../images/ic_mobile_g.svg) no-repeat center left 5px;background-size: 20px;}
.user_pop .inner_cont .info_box{position: absolute;width: 290px;border: 1px solid #ddd;background: #fff;top: 24px;right: 60%;}
.user_pop .inner_cont .info_box .txt{font-size: 15px;font-weight: 500;color: #fff;background: #666;padding: 8px 10px 8px 20px;}
.user_pop .inner_cont .info_box .txt_ul{padding: 10px 20px;}
.user_pop .inner_cont .info_box .txt_ul li{border-bottom: 1px solid #ddd;font-size: 15px;color: #666;padding: 10px 0;font-weight: 400;}
.user_pop .inner_cont .info_box .txt_ul li:last-child{border-bottom: none;}
.user_pop .inner_cont .info_box .txt_ul li span:first-child{color: #222;font-weight: 500;position: relative;padding-left: 10px;}
.user_pop .inner_cont .info_box .txt_ul li span:first-child::after{position: absolute;content: '';width: 3px;height: 3px;background: #222;border-radius: 50%;top: 9px;left: 0;}

/* 온라인경매 - 메세지 팝업 */
.message_pop{position: absolute;z-index: 2000;top: 0;right: 0;}
.message_pop .dim{position: fixed;width: 100%;height: 100%;top: 0;left: 0;background: rgba(0,0,0,0.5);}
.message_pop .inner_cont{position: relative;z-index: 1;width: 490px;border: 1px solid #ddd;}
.message_pop .inner_cont .scroll_wrap{width:calc(100% - 94px);min-height: 71px; max-height: 120px;border-right: 1px solid #fff;background: #fff;}
.message_pop .inner_cont .txt_ul li{font-size: 15px;color: #666;padding: 8px 8px 8px 18px;cursor: default;}
.message_pop .inner_cont .txt_ul li.on{color: #222;font-weight: 500;background: #e3f0ff;}
.message_pop .inner_cont .btn_s{display: block;height: 100%;width: 94px;font-weight: 500;line-height: 1.4;height:100%;position: absolute;right: 0;top: 0;}
.message_pop2{bottom: 0;top: auto;}
/* .message_pop2 .inner_cont{width:340px;} */
/* .message_pop2 .inner_cont .scroll_wrap{height: 80px;} */
.message_pop2 .inner_cont .txt{width: 100%; font-size: 15px;font-weight: 500;color: #fff;background: #666;padding: 8px 8px 8px 18px;}
/* .message_pop2 .inner_cont .btn_s{height:80px;} */

/* 송품장출력 페이지 */
.page_print{padding: 40px 0;max-width: 910px;margin: 0 auto;}
.page_print .top_tit{font-size: 32px;font-weight: 600;text-align: center;padding-bottom:24px;letter-spacing: 0.24em;}
.page_print .gray_box{background-color: #EDEDED;text-align: center;padding: 11px;font-size: 17px;}
.page_print .custom_table table thead th {padding: 9px 0;}
.page_print .custom_table .td_box li{min-height: 15px;border-bottom: 1px solid #ddd;font-size: 14px;padding: 3px 0;}
.page_print .custom_table .td_box li:last-child{border-bottom:none;}
.page_print .logo{font-size: 24px;font-weight: 700;letter-spacing:  0.24em;}
.page_print .btn_type3{font-size: 15px !important;padding:0 20px !important;}
@media print {
    .custom_table th {print-color-adjust: exact;}
    .page_print .gray_box{print-color-adjust: exact;}
    .page_print .btn_type3{display: none;}
    html, body { -webkit-print-color-adjust:exact; width: 210mm; height: 297mm; }
    table { page-break-inside:auto; }
    tr    { page-break-inside:avoid; page-break-after:auto; }
    thead { display:table-header-group; }
    tfoot { display:table-footer-group; }
}

/* 경매사 - 통계 */
.btn_pdf{height: 40px;line-height: 38px;border: 1px solid #ddd;font-size: 14px;color: #666;background: #fff url(../../images/ic_pdf.svg) no-repeat center left 15px;text-align: left;padding:0 15px 0 40px;}
.btn_excel{height: 40px;line-height: 38px;border: 1px solid #ddd;font-size: 14px;color: #666;background: #fff url(../../images/ic_excel.svg) no-repeat center left 15px;text-align: left;padding:0 15px 0 43px;}
.page_pdf .custom_table tbody td{font-size: 13px;}