/* custom_input */
.mo_wrap .custom_input{height: 36px;}
.mo_wrap .custom_input input{font-size: 14px;padding: 0 10px;}
.mo_wrap .custom_input input::-webkit-input-placeholder {font-size: 14px;} 
.mo_wrap .custom_input input:-moz-placeholder {font-size: 14px;} 
.mo_wrap .custom_input input::-moz-placeholder {font-size: 14px;} 
.mo_wrap .custom_input input:-ms-input-placeholder {font-size: 14px;}

/* txt_input */
.mo_wrap .txt_input input{padding-right: 15px;}

/* date_input */
.mo_wrap .date_input{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;position: relative;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.mo_wrap .date_input .custom_input{height: 36px;width: calc(50% - 7px);}
.mo_wrap .date_input .custom_input:nth-child(2)::after{position: absolute;content: '~';width: 14px;height: 100%;font-size: 14px;left: -15px;line-height: 34px;}
.mo_wrap .date_input .custom_input input{height: 34px;background-size: 14px;padding: 0 20px 0 10px;}

/* input_box */
.mo_wrap .drop_input_box>.drop_wrap{width:60px;}
.mo_wrap .drop_input_box>.custom_input{width: calc(100% - 65px);}

/* custom_select */
.mo_wrap .custom_select select{height: 36px;font-size: 14px !important;}

/* custom_check */
.custom_check2 label{padding: 6px 10px;border-bottom: 1px solid #ddd;position: relative;}
.custom_check2 label .bg{position: absolute;width: calc(100% + 20px);height: 100%;top: 0;left: -10px;z-index: -1;background: #F8F8F8;}
.custom_check2 label input:checked + .check + .txt + .bg{background: #E3F0FF;}
.custom_check3 label{display: block;}
.custom_check3 label .btn{display: inline-block;font-size: 12px;color: #008ECD;border:1px solid #008ECD;padding: 2px 10px;}
.custom_check3 label input:checked + .btn {color: #fff;background: #008ECD;}