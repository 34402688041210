@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Montaga&display=swap');

/************************* 공통 *****************************/
html,.body{height: auto;}
.body {box-sizing: border-box;overflow-x: hidden;line-height: 1.3;}
.body *{box-sizing: border-box;}
.body,.body input,.body select,.body textarea,.body table{
	font-family:'Noto Sans KR','맑은 고딕', 'Malgun Gothic',sans-serif;
	font-size: 18px;
	letter-spacing: -0.025em;
   	color: #222;
}
.body button{font-family:'Noto Sans KR','맑은 고딕', 'Malgun Gothic',sans-serif;letter-spacing: -0.025em;}
#wrap{width:100%;text-align:left; min-width:320px; /*overflow: hidden;*/}
#wrap video{max-width: 100%;}
.pc_wrap .cont{max-width: 1200px;margin: 0 auto;}
.pc_wrap .cont2{max-width: 580px;margin: 0 auto;}


/* btn */
.btn_type{display: block;font-size: 16px;font-weight: 500;color: #fff;text-align: center;height: 50px;line-height: 49px;background: #008ECD;border: 1px solid #008ECD;padding: 0 30px;}
.btn_type2{display: block;font-size: 16px;font-weight: 500;color: #fff;text-align: center;height: 50px;line-height: 49px;background: #999;border: 1px solid #999;padding: 0 30px;}
.btn_type3{display: block;font-size: 16px;font-weight: 500;color: #fff;text-align: center;height: 50px;line-height: 49px;background: #222;border: 1px solid #222;width: auto;padding: 0 30px;}
.btn_type4{display: block;font-size: 16px;color: #666;text-align: center;height: 50px;line-height: 49px;background: #fff;border: 1px solid #ddd;width: auto;padding: 0 30px;}
.btn_type4 img{margin-right: 10px;}
.btn_down{display: block;font-size: 16px;color: #222;background: #fff url(../../images/ic_down.svg) no-repeat center right 24px;padding: 14px 54px 14px 24px;border-radius: 50px;}
.btn_s{display: block;font-size: 14px;color: #fff;text-align: center;height: 40px;line-height: 40px;background: #008ecd;padding: 0 15px;}
.btn_s2{display: block;font-size: 14px;color: #fff;text-align: center;height: 40px;line-height: 40px;background: #333;padding: 0 15px;}
.btn_s3{display: block;font-size: 14px;color: #fff;text-align: center;height: 40px;line-height: 40px;background: #999;padding: 0 15px;}
.btn_s4{display: block;font-size: 14px;color: #333;text-align: center;height: 40px;line-height: 40px;background: #fff;padding: 0 15px;border: 1px solid #333;}
.btn2_s{display: inline-block;font-size: 14px;color: #666;text-align: center;height: 27px;line-height: 26px;border: 1px solid #ddd;padding: 0 10px;background: #fff;}
.btn2_s2{display: inline-block;font-size: 14px;color: #333;text-align: center;height: 27px;line-height: 26px;border: 1px solid #333;padding: 0 10px;background: #fff;}
.btn3_s{display: inline-block;font-size: 13px;color: #666;text-align: center;height: 35px;line-height: 34px;border: 1px solid #ddd;padding: 0 20px;background: #fff;}
.btn3_s2{display: inline-block;font-size: 13px;color: #fff;text-align: center;height: 35px;line-height: 34px;border: 1px solid #222;background: #222;}
.btn3_s3{display: inline-block;font-size: 13px;color: #222;text-align: center;height: 35px;line-height: 34px;border: 1px solid #222;background: #fff;}
.btn3_s4{display: inline-block;font-size: 13px;color: #fff;text-align: center;height: 35px;line-height: 34px;border: 1px solid #008ECD;background: #008ECD;}

/*pagination*/
 .pagination {text-align: center;clear:both; font-size:0;padding-top:50px;}
 .pagination .num_box {display: inline-block; width: auto;height: auto;border: none;}
 .pagination .num_box li{display: inline-block;}
 .pagination .num_box li button {display: inline-block;line-height: 26px;color:#222;text-align:center;font-size:15px;width:26px;height: 26px; font-weight: 500;margin:0 5px;border-radius: 50%;}
 .pagination .num_box li.on button{color: #fff;background:#008ecd;}
 .pagination button.prev, .pagination button.next {text-align:center;font-size:0;width:26px; height:26px;display: inline-block;line-height: 26px;vertical-align: top;color:#999;box-sizing:border-box;padding:0 10px;}
 .pagination button.prev{margin-right:15px; background:url(../../images/page_prev.svg) no-repeat center;}
 .pagination button.next{margin-left:15px; background:url(../../images/page_next.svg) no-repeat center;}
 .pagination button span {display:none;outline: medium none;overflow: hidden;text-indent: -9999em;}

/* 스크롤바 */
 .scroll_wrap{overflow-y: auto;overflow-x: hidden;}
 .scroll_wrap.over_x{overflow: auto !important;}
 .scroll_wrap::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 4px;
	height: 4px;
}
 .scroll_wrap::-webkit-scrollbar-track {
	background-color: rgb(221,221,221,0); 
  	border-radius: 10px;
}

 .scroll_wrap::-webkit-scrollbar-thumb {
	background-color: #b2b2b2; 
  	border-radius: 10px;
}


/************************* header *****************************/
 .header .cont{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;height: 100px;}
 .header .logo{font-size: 0;}
 .header .menu_ul{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
 .header .menu_ul li{margin-right: 47px;}
 .header .menu_ul li:last-child{margin-right: 0;}
 .header .menu_ul li .txt{color: #222;font-weight: 500;font-size: 18px;}


/************************* main *****************************/
 .main_slider{width: 100%;}
 .main_slider .swiper-slide{height: 500px;overflow: hidden;}
 .main_slider .swiper-slide .img_box{transform: scale(1);}
 .main_slider .slide1 .img_box{width: 100%;height:100%;background: url('../../images/main_slide01.jpg') no-repeat center;}
 .main_slider .slide2 .img_box{width: 100%;height:100%;background: url('../../images/main_slide02.jpg') no-repeat center;}
 .main_slider .swiper-slide .img_box.on{transform: scale(1.1);transition: ease-in-out 3s;}
 .main_slider .txt_box{position: absolute;top: 50%;left: calc(calc(100% - 1200px)/2);transform: translateY(-50%);z-index: 1;color: #fff;}
 .main_slider .btn_box{position: absolute;z-index: 20;left: calc(calc(100% - 1200px)/2);bottom: 75px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;}
 .main_slider .btn_box .btn_prev{font-size: 0;width: 28px;height: 24px;background: url(../../images/prev_arrow.svg) no-repeat center left;cursor: pointer;}
 .main_slider .btn_box .btn_next{font-size: 0;width: 28px;height: 24px;background: url(../../images/next_arrow.svg) no-repeat center right;cursor: pointer;}
 .main_slider .btn_box .slider_pagination{width: auto;color: #fff;font-weight: 400;line-height: 1.3;}
 .main_slider .btn_box .slider_pagination .swiper-pagination-total{color: rgba(255,255,255,0.5);padding-left: 32px;position: relative;}
 .main_slider .btn_box .slider_pagination .swiper-pagination-total::after{position: absolute;content: '';width: 2px;height: 2px;background: #fff;border-radius: 50%;top: 50%;left: 15px;transform: translateY(-50%);}
 .main_slider .login_cont{position: absolute;top: 50%;right: calc(calc(100% - 1200px)/2);transform: translateY(-50%);z-index: 1;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: end; -ms-flex-pack: end;-webkit-justify-content:flex-end; justify-content:flex-end;}
 .login_box{width: 400px;background: rgba(255,255,255,0.95);padding: 30px 40px 0;}
 .login_box .tit{font-size: 24px;font-weight: 500;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
 .login_box .name{max-width: calc(100% - 90px);}
 .login_box .tit .txt{font-size: 17px;color: #999;padding-left: 30px;position: relative;font-weight: 400;}
 .login_box .tit .txt::after{position: absolute;content: '';width: 1px;height: 15px;background: #ddd;left: 15px;top: 50%;transform: translateY(-50%);}
 .login_box .link_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;padding: 15px 0 30px;}
 .login_box .link_box .link_find{font-size: 14px;color: #333;position: relative;margin-right: 16px;}
 .login_box .link_box .link_find::after{position: absolute;content: '';width: 1px;height: 15px;background: #ddd;right: -8px;top: 50%;transform: translateY(-50%);}
 .login_box .link_box .link_find:nth-child(2){margin-right: 0;}
 .login_box .link_box .link_find:nth-child(2)::after{display: none;}
 .login_box .link_box .link_join{font-size: 14px;font-weight: 500;color: #008ECD;}
 .login_box .alert_box{width: calc(100% + 80px);margin-left: -40px;padding: 16px 5px;text-align: center;font-size: 14px;color: #666;background: #E2EFFF;}
 .login_box .alert_box span{padding-left: 26px;background: url(../../images/ic_alert.svg) no-repeat center left;}
 .login_box .sub_tit{font-size: 13px;color: #666;width: 25%;}
 .login_box .blue_box{width: calc(100% + 80px);margin: -30px 0 0 -40px;background: #e2efff;padding: 17px 40px;}
 .login_box .date_input .custom_input{width: 100%;}
 .login_box .deal_box{border-top: 1px solid #222;margin: 10px auto;}
 .deal_box .txt_ul {display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
 .deal_box .txt_ul li{padding:3px 8px;font-size: 12px;color: #666;text-align: right;width: 33.333%;border-bottom: 1px solid #ddd;}
 .deal_box .txt_ul li p{text-align: left;}
 .deal_box .txt_ul li strong{font-size: 20px;color: #333;}
 .login_box .auth_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;padding-bottom: 7px;}
 .login_box .auth_box p{font-size: 14px;color: #666;}
 .login_box .auth_box .right_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;font-size: 14px;color:#FF0000;}
 .login_box .auth_box .right_box button{font-size: 14px;color: #fff;padding: 3px 10px;background:#666666;margin-left: 8px;}
 .login_box .alert_txt{text-align: center;padding: 10px 5px 46px;font-size: 14px;color: #666;text-align: center;}
 .login_box .alert_txt span{padding-left: 26px;background: url(../../images/ic_alert.svg) no-repeat center left;}
 .page_main .top_tit .txt{font-size: 14px;color: #008ecd;letter-spacing: 0.5em;padding-bottom: 10px;}
 .page_main .top_tit .tit{font-size: 30px;font-weight: 600;}
 .purpose_wrap{padding: 78px 0 137px;position: relative;}
 .purpose_ul{padding-top: 46px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;}
 .purpose_ul li{width: calc(25% - 20.25px);border: 1px solid #ddd;padding: 40px 20px 42px 35px;position: relative;transition: all .3s;cursor: default;}
 .purpose_ul li .num{position: absolute;color: #ddd;font-weight: 600;right: 24px;top: 22px;transition: all .3s;}
 .purpose_ul li .img_box{width: 85px;height: 85px;border-radius: 10px;background:#f8f8f8 url(../../images/ic_purpose01.svg) no-repeat center;margin-bottom: 30px;transition: all .3s;}
 .purpose_ul li:nth-child(2) .img_box{background:#f8f8f8 url(../../images/ic_purpose02.svg) no-repeat center;}
 .purpose_ul li:nth-child(3) .img_box{background:#f8f8f8 url(../../images/ic_purpose03.svg) no-repeat center;}
 .purpose_ul li:nth-child(4) .img_box{background:#f8f8f8 url(../../images/ic_purpose04.svg) no-repeat center;}
 .purpose_ul li .txt{font-size: 20px;font-weight: 500;padding-bottom: 10px;transition: all .3s;}
 .purpose_ul li .txt2{font-size: 16px; color: #666;transition: all .3s;}
 .purpose_ul li:hover{border-color: #008ecd;background: #008ecd;}
 .purpose_ul li:nth-child(2):hover{border-color: #00b2cd;background: #00b2cd;}
 .purpose_ul li:nth-child(3):hover{border-color: #8bdb0d;background: #8bdb0d;}
 .purpose_ul li:nth-child(4):hover{border-color: #dbba0d;background: #dbba0d;}
 .purpose_ul li:hover .num{color: #fff;}
 .purpose_ul li:hover .img_box{background: rgba(255,255,255,0.5) url(../../images/ic_purpose01_on.svg) no-repeat center;}
 .purpose_ul li:nth-child(2):hover .img_box{background: rgba(255,255,255,0.5) url(../../images/ic_purpose02_on.svg) no-repeat center;}
 .purpose_ul li:nth-child(3):hover .img_box{background: rgba(255,255,255,0.5) url(../../images/ic_purpose03_on.svg) no-repeat center;}
 .purpose_ul li:nth-child(4):hover .img_box{background: rgba(255,255,255,0.5) url(../../images/ic_purpose04_on.svg) no-repeat center;}
 .purpose_ul li:hover .txt{color: #fff;}
 .purpose_ul li:hover .txt2{color: #fff;}
 .purpose_wrap .down_box{position: absolute;width: 100%;max-width: 1200px; bottom: -74px;padding: 33px 100px 35px;background: linear-gradient(to right, #00b2cd, #008ecd);left: 50%;transform: translateX(-50%);border-radius: 100px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
 .purpose_wrap .down_box .txt_box h5{color: #fff;font-size: 22px;font-weight: 500;}
 .purpose_wrap .down_box .txt_box p{padding-top: 5px;color: #fff;font-size: 16px;}
 .purpose_wrap .down_box .btn_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;}
 .purpose_wrap .down_box .btn_box .btn_down{transition: all .3s;}
 .purpose_wrap .down_box .btn_box .btn_down:hover{box-shadow: 0 4px 4px rgba(24,52,107,0.25);}
 .notice_wrap{padding: 150px 0 70px;background: #f8f8f8;}
 .notice_wrap .cont{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;}
 .notice_wrap .top_tit .tit{color: #222;padding-right: 24px;background: url(../../images/arrow.svg) no-repeat center right;}
 .notice_wrap .notice_ul{width: 80%;border-top: 2px solid #222;}
 .notice_wrap .notice_ul li{border-bottom: 1px solid #ddd;padding: 25px 20px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
 .notice_wrap .notice_ul li .ellipsis{color: #222;width: 85%;}
 .notice_wrap .notice_ul li .date{font-size: 16px;color: #999;}


/************************* footer *****************************/
 .footer{background: #434d52;padding: 45px 0;height: 208px;}
 .footer .cont{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-ms-flex-wrap: wrap;flex-wrap: wrap;}
 .footer .txt_box{width: calc(100% - 190px);}
 .footer .txt_ul li{display: inline-block;font-size: 15px;color: #999;padding-right: 16px;position: relative;}
 .footer .txt_ul li::after{position: absolute;content: '｜';color: #4f595e;right: 0;width: 16px;height: 100%;}
 .footer .txt_ul li:last-child{padding-right: 0;}
 .footer .txt_ul li:last-child::after{display: none;}
 .footer .txt_ul li a{color: #999;}
 .footer .copy{font-size: 15px;color: #707070;padding-top: 15px;}


/************************* popup *****************************/
.pop_wrap{position: fixed;width: 100%;height: 100%;top: 0;left: 0;z-index: 2000;}
.pop_wrap .dim{position: absolute;width: 100%;height: 100%;top: 0;left: 0;background: rgba(0,0,0,0.5);}
.pop_wrap .pop_cont{width: 1100px;position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;overflow: hidden;box-shadow: 0 0 10px rgb(183 183 183 / 20%);padding: 30px;}
.pop_wrap .pop_cont2{width: 400px;}
.pop_wrap .pop_cont3{width: 940px;}
.pop_wrap .pop_cont4{width: 500px;}
.pop_wrap .pop_close{position: absolute;width: 50px;height: 60px;background: url(../../images/ic_close.svg) no-repeat left bottom;font-size: 0;top: 0;right: 0;}
.pop_wrap .pop_tit{padding-bottom: 20px;}
.pop_wrap .pop_tit .tit{font-size: 22px;font-weight: 600;}
.pop_wrap .pop_tit .txt{font-size: 12px;color: #666;}
.pop_wrap .pop_cont .scroll_wrap{max-height: 60vh;}
.pop_wrap .pop_cont .scroll_wrap>.img_box>img{max-height: calc(60vh - 70px);}
.pop_wrap .pop_cont .scroll_wrap>.img_box>video{max-height: calc(60vh - 70px);}
.pop_wrap .pop_cont .search_box{width: calc(100% + 60px);margin-left: -30px;}
.pop_wrap .pop_cont .img_box video{max-width: 100%;}

/* 경매 카운트팝업 */
.pop_wrap .count_cont{width: 340px;height: 340px;background: #000;border-radius: 50%;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);text-align: center;color: #ffe500;}
.pop_wrap .count_cont .num{font-size: 200px;font-weight: 600;line-height: 1.1;}
.pop_wrap .count_cont .txt{font-size: 50px;font-weight: 600;}
.pop_wrap .count_cont.end {color: #ff0000;}

/* 출하주 - 거래내역팝업 */
.deal_popup .pop_cont{overflow: visible;}
.deal_popup .inner_box{border-top: 1px solid #333;padding-top: 10px;}
.deal_popup .inner_box .date_input .custom_input{width: 130px;}
.deal_popup .inner_box .deal_box{border-top: 1px solid #ddd;}

/* 경매사 - 사진/동영상 팝업 */
.pop_wrap .img_video_box{border-top: 1px solid #ddd;padding: 10px 0;}
.pop_wrap .img_video_box li{width: calc(33.333% - 6.666px);min-height: 150px;background: url(../../images/img_none.svg)no-repeat center;background-size: 49px;}
.pop_wrap .img_video_box li:last-child{background: url(../../images/video_upload.svg)no-repeat center;}

/* 다음주소팝업 */
.daum_pop{position: fixed;width: 100%;height: 100% !important;top: 0;left: 0;display: flex;align-items: center;justify-content: center;z-index: 999;-webkit-box-orient: vertical; -ms-flex-direction: column; flex-direction: column;}
.daum_pop .top_box{width: 400px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: end; -ms-flex-pack: end;-webkit-justify-content: flex-end; justify-content: flex-end;}
.daum_pop .btn_close{width: 25px;height: 25px;background: url(../../images/ic_close.svg) no-repeat center;font-size: 0;}
