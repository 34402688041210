@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Montaga&display=swap');

/************************* 공통 *****************************/
html,.body{height: auto;}
.body {box-sizing: border-box;overflow-x: hidden;}
.body *{box-sizing: border-box;}
.mo_wrap *{word-break: keep-all;}
.mo_wrap,.mo_wrap input,.mo_wrap select,.mo_wrap textarea,.mo_wrap table{
	font-family:'Noto Sans KR','맑은 고딕', 'Malgun Gothic',sans-serif;
	font-size: 14px;
	letter-spacing: -0.025em;
   	color: #222;
}
.mo_wrap button{font-family:'Noto Sans KR','맑은 고딕', 'Malgun Gothic',sans-serif;letter-spacing: -0.025em;}
#wrap{width:100%;text-align:left; min-width:320px; /*overflow: hidden;*/}
#wrap video{max-width: 100%;}
.mo_wrap .cont{padding: 0 20px;}
.mo_wrap .cont2{padding: 0 10px;}

/* btn */
.mo_wrap .btn_type{font-size: 13px;height: 43px;line-height: 40px;}
.mo_wrap .btn_type3{font-size: 13px;height: 43px;line-height: 40px;padding: 0 10px;}
.mo_wrap .btn_type4{font-size: 13px;height: 43px;line-height: 40px;padding: 0 10px;}
.mo_wrap .btn_s{display: block;font-size: 13px;color: #fff;text-align: center;height: 40px;line-height: 40px;background: #008ecd;padding: 0 15px;}
.mo_wrap .btn_s2{display: block;font-size: 13px;color: #fff;text-align: center;height: 40px;line-height: 40px;background: #333;padding: 0 15px;}
.mo_wrap .btn_s3{display: block;font-size: 13px;color: #fff;text-align: center;height: 40px;line-height: 40px;background: #999;padding: 0 15px;}
.mo_wrap .btn2_s{display: inline-block;font-size: 13px;color: #666;text-align: center;height: 27px;line-height: 26px;border: 1px solid #ddd;padding: 0 10px;background: #fff;}
.mo_wrap .btn2_s2{display: inline-block;font-size: 13px;color: #333;text-align: center;height: 27px;line-height: 26px;border: 1px solid #333;padding: 0 10px;background: #fff;}
.mo_wrap .btn3_s{display: inline-block;font-size: 13px;color: #666;text-align: center;height: 35px;line-height: 34px;border: 1px solid #ddd;padding: 0 20px;background: #fff;}
.mo_wrap .btn3_s2{display: inline-block;font-size: 13px;color: #fff;text-align: center;height: 35px;line-height: 34px;border: 1px solid #222;background: #222;}
.mo_wrap .btn3_s3{display: inline-block;font-size: 13px;color: #222;text-align: center;height: 35px;line-height: 34px;border: 1px solid #222;background: #fff;}


/*pagination*/
.mo_wrap .pagination {text-align: center;clear:both; font-size:0;padding:20px 0;}
.mo_wrap .pagination .num_box {display: inline-block; width: auto;height: auto;border: none;}
.mo_wrap .pagination .num_box li{display: inline-block;}
.mo_wrap .pagination .num_box li button {display: inline-block;line-height: 26px;color:#222;text-align:center;font-size:15px;width:26px;height: 26px; font-weight: 500;margin:0 5px;border-radius: 50%;}
.mo_wrap .pagination .num_box li.on button{color: #fff;background:#008ecd;}
.mo_wrap .pagination button.prev, .pagination button.next {text-align:center;font-size:0;width:26px; height:26px;display: inline-block;line-height: 26px;vertical-align: top;color:#999;box-sizing:border-box;padding:0 10px;}
.mo_wrap .pagination button.prev{margin-right:15px; background:url(../../images/page_prev.svg) no-repeat center;}
.mo_wrap .pagination button.next{margin-left:15px; background:url(../../images/page_next.svg) no-repeat center;}
.mo_wrap .pagination button span {display:none;outline: medium none;overflow: hidden;text-indent: -9999em;}

/* header */
.mo_wrap .header {height: 50px;padding: 0 5px;position: relative;border-bottom: 1px solid #ddd;}
.mo_wrap .header .inner{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.mo_wrap .header .btn_back{padding-left: 20px;height: 50px; background: url(../../images/mo/ic_back.svg) no-repeat center left;font-size:14px;color: #333;line-height: 48px;}
.mo_wrap .header .btn_menu{width: 40px;height: 50px;background: url(../../images/mo/ic_menu.svg) no-repeat center;font-size: 0;text-indent: -9999px;}
.mo_wrap .header .tit{position: absolute;top: 13px;left: 50%;transform: translateX(-50%);font-size: 17px;font-weight: 500;width: calc(100% - 90px);text-align: center;}
.mo_wrap .header .gnb_wrap{position: fixed;width: 100%;height: 100%;top: 0;right:0;z-index: 900;display: none;}
.mo_wrap .header .gnb_wrap.on{display: block;}
.mo_wrap .header .gnb_wrap .dim{position: absolute;width: 100%;height: 100%;top: 0;right: 0;}
.mo_wrap .header .gnb_wrap .gnb_area{position: absolute;width: 80%;height: 100%;top: 0;right: 0;background-color: #fff;padding:20px 30px;border-left: 1px solid #ddd;}
.mo_wrap .header .gnb_wrap .gnb_area .gnb_logo{width: 160px;margin-bottom: 40px;display: block;}
.mo_wrap .header .gnb_wrap .gnb_area .gnb>li{padding-bottom: 25px;}
.mo_wrap .header .gnb_wrap .gnb_area .gnb>li a{color: #222;font-size: 16px;}
.mo_wrap .header .gnb_wrap .btn_close{position: absolute;width: 40px;height: 50px;background: url(../../images/mo/ic_close.svg) no-repeat center;background-size: 20px; font-size: 0;text-indent: -9999px;top: 0;right: 5px;}

/* main */
.mo_wrap .page_main{min-height: 100vh;}
.mo_wrap .logo_box {text-align: center;padding: 60px 0;}
.mo_wrap .logo_box p{font-size: 20px;font-weight: 700;padding-top: 30px;}
.mo_wrap .login_box{width: 100%;padding: 0 20px;}
.mo_wrap .login_box.login{background: #F5F5F5;position: relative;min-height: 100vh;padding-bottom: 50px;}
.mo_wrap .login_box .tit{font-size: 18px;}
.mo_wrap .login_box .tit .txt{font-size: 15px;padding-left: 20px;}
.mo_wrap .login_box .tit .txt::after{height: 10px;left: 10px;}
.mo_wrap .login_box .alert_box{background: #F8F8F8;width: 100%;margin-left: auto;}
.mo_wrap .m_login_box{width: calc(100% + 40px);margin-left: -20px;padding: 14px 20px 20px;position: relative;}
.mo_wrap .m_login_box .bg_img{position: absolute;top: 0;left: 0;width: 100%;z-index: 1;}
.mo_wrap .m_login_box .top_box{position: relative;z-index: 2;color: #fff;}
.mo_wrap .m_login_box .top_box button{font-size: 10px;font-weight: 500;color: #fff;border: 1px solid #fff;width: 50px;padding: 3px 0;}
.mo_wrap .m_login_box .top_box .txt{font-size: 10px;padding: 30px 0 5px;font-weight: 300;letter-spacing: 0.1em;}
.mo_wrap .m_login_box .top_box .txt2{font-size: 20px;}
.mo_wrap .m_login_box .round_box{position: relative;z-index: 2;background: #fff;border-radius: 8px;padding: 20px 15px;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);margin-top: 28px;}
.mo_wrap .m_login_box .txt_ul {max-width: 300px;}
.mo_wrap .m_login_box .txt_ul li{padding-bottom: 8px;}
.mo_wrap .m_login_box .txt_ul li:last-child{padding-bottom: 0;}
.mo_wrap .m_login_box .txt_ul li .txt{display: inline-block;font-size: 13px;background: #F4F7FC;border-radius: 20px;padding: 5px;width: 90px;text-align: center;margin-right: 10px;}
.mo_wrap .m_login_box .txt_ul li .date_input .custom_input{width: 120px;}
.mo_wrap .m_login_box .deal_box .txt_ul{max-width: 100%;}
.mo_wrap .page_main .bottom_box{position: relative;z-index: 2;}
.mo_wrap .page_main .bottom_box .tit_box .txt{font-size:18px;font-weight: 700;padding-bottom: 5px;}
.mo_wrap .page_main .bottom_box .tit_box .txt2{font-size:12px;color:#999;padding-bottom: 10px;}
.mo_wrap .page_main .bottom_box .menu_ul{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.mo_wrap .page_main .bottom_box .menu_ul li{width: calc(50% - 5px);background: #fff;box-shadow: 0px 2px 6px rgba(131, 131, 131, 0.25);border-radius: 5px;margin-bottom: 10px;}
.mo_wrap .page_main .bottom_box .menu_ul li a{display: block;height: 140px;padding-bottom: 5px;}
.mo_wrap .page_main .bottom_box .menu_ul li .img{height: 65%;padding-top: 32px;text-align: center;}
.mo_wrap .page_main .bottom_box .menu_ul li .txt{height: 35%;font-size: 15px;font-weight: 500;color: #333;line-height: 1.2;text-align: center;}
.mo_wrap .page_main .call_txt{width:100%;position: absolute;bottom: 0;left: 0;}
.mo_wrap .page_main .call_txt a{display: block;background: #F8F8F8;font-size: 12px;color: #333;text-align: center;padding: 10px;}

/* popup */
.pop_wrap .mo_pop_cont{width: 100%;height:100%;position: fixed;top:0;left:0;background: #fff;overflow: hidden;box-shadow: 0 0 10px rgb(183 183 183 / 20%);}
.pop_wrap .mo_pop_cont .mo_pop_tit{position: relative;min-height: 50px;padding: 10px 50px;border-bottom: 1px solid #ddd;}
.pop_wrap .mo_pop_cont .mo_pop_tit .tit{text-align: center;font-size: 17px;font-weight: 500;}
.pop_wrap .mo_pop_cont .mo_pop_tit .pop_close{height: 50px;background-position: center;background-size:17px;}
.pop_wrap .mo_pop_cont>.scroll_wrap{max-height: calc(100% - 50px);}
.pop_wrap .mo_pop_cont>.scroll_wrap2{height: calc(100% - 133px);border-bottom: 1px solid #ddd;}
.pop_wrap .mo_pop_cont>.scroll_wrap.m_h_300{max-height: 300px;}
.pop_wrap .mo_pop_cont .media_ul li{display: block;}
.pop_wrap .mo_pop_cont .media_ul.li_center li{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: center; -ms-flex-pack: center;-webkit-justify-content: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.pop_wrap .mo_pop_cont.small_pop{width: calc(100% - 60px);height: auto;max-height:90vh;top: 50%;left: 50%;transform: translate(-50%,-50%);padding: 30px 20px;}
.pop_wrap .mo_pop_cont.small_pop .mo_pop_tit{padding: 0 30px 10px 0;min-height: auto;}
.pop_wrap .mo_pop_cont.small_pop .mo_pop_tit .tit{text-align: left;}
.pop_wrap .mo_pop_cont.small_pop .mo_pop_tit .txt{font-size: 13px;color: #666;}
.pop_wrap .mo_pop_cont.small_pop .mo_pop_tit .pop_close{width: 30px;height: 30px;background-position: right top 3px;}
.pop_wrap .mo_pop_cont.small_pop .pop_wrap .mo_pop_cont.small_pop {width:100%;}

/* 다음주소찾기팝업 */
.mo_wrap .daum_pop .top_box {width: 300px;}

/* 물품분할하기 팝업 */
.num_btn_box .btn_up{display: block;width: 36px;height: 36px;border: 1px solid #ddd;background: url(../../images/mo/ic_up.svg) no-repeat center;font-size: 0;margin-right: 3px;}
.num_btn_box .btn_down{display: block;width: 36px;height: 36px;border: 1px solid #ddd;background: url(../../images/mo/ic_down.svg) no-repeat center;font-size: 0;border-radius: 0;padding: 0;}
.num_btn_box .btn3_s2{width: calc(100% - 78px);height: 36px;}

/* 중도매인 - 온라인경매 상세 - 메모팝업 */
.pop_wrap .mo_pop_cont.memo_pop{background: #F8F8F8;}
.pop_wrap .mo_pop_cont.memo_pop .mo_pop_tit{border-bottom: none;}
.pop_wrap .mo_pop_cont.memo_pop .mo_pop_tit .tit{font-weight: 700;}
.pop_wrap .mo_pop_cont.memo_pop .mo_pop_tit .tit span{padding-left: 9px;}
.pop_wrap .mo_pop_cont.memo_pop .txt_box {border: 1px solid #ddd;background: #fff;min-height: 100px;padding: 10px;text-align: left;}

/* 중도매인 - 온라인경매 상세 - 경매카운트팝업 */
.mo_wrap .pop_wrap .count_cont{width: 280px;height: 280px;}
.mo_wrap .pop_wrap .count_cont .num{font-size: 160px;line-height: 1.2;}
.mo_wrap .pop_wrap .count_cont .txt{font-size: 40px;}

/* 경매사 - 경매/입찰결과 - 물품상세팝업 */
.mo_wrap .bid_box{display: block;}
.mo_wrap .bid_box .btn_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;width: 100%;}
.mo_wrap .bid_box .btn_box button{width: calc(50% - 5px);}


