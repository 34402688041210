/* 공통 */
.mo_wrap .tag{font-size: 12px;padding: 2px 8px;}
.mo_wrap .tag + .btn2_s2{font-size: 12px;height: 22px;line-height: 20px;}
.media_ul{border-top: 1px solid #222;padding: 6px 0;}
.media_ul li{padding:0 10px 6px;text-align: center;border-bottom: 1px solid #ddd;height: 160px;overflow: hidden; display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: center; -ms-flex-pack: center;-webkit-justify-content: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.media_ul li .thumb_box{height: 100%;overflow: hidden;}
.btn_half_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;width: 100%;}
.btn_half_box>*{width: calc(50% - 5px) !important;}
.btn_view{display: inline-block;font-size: 13px;color: #999;border: 1px solid #ddd;border-radius: 2px;padding-left:24px;background: url(../../images/mo/ic_view.svg) no-repeat center left 8px;width: 56px;height: 27px;line-height: 25px;text-align: left;}
.btn_bid{display: inline-block;font-size: 13px;color: #999;border: 1px solid #ddd;border-radius: 2px;padding-left:24px;background: url(../../images/mo/ic_bid.svg) no-repeat center left 8px;width: 80px;height: 27px;line-height: 25px;text-align: left;}
.btn_modify{display: inline-block;font-size: 13px;color: #999;border: 1px solid #ddd;border-radius: 2px;padding-left:24px;background: url(../../images/mo/ic_modify.svg) no-repeat center left 8px;width: 56px;height: 27px;line-height: 25px;text-align: left;}
.mo_wrap .tag2{display: block;border-radius: 0;background: #00b2cd;padding: 5px 10px;}
.btn_s.h_24,.btn_s3.h_24{height: 24px;line-height: 24px;display: inline-block;padding: 0 8px;font-size: 12px;}

/* search_box */
.mo_wrap .search_box{background: #f8f8f8;padding: 20px 10px;display: block;}
.mo_wrap .search_box>ul>li{width: 100%;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;padding-bottom: 10px;}
.mo_wrap .search_box>ul>li:last-child{padding-bottom: 0;}
.mo_wrap .search_box>ul>li .txt{font-weight: 500;width: 70px;}
.mo_wrap .search_box>ul>li .txt + div{width: calc(100% - 70px);}
.mo_wrap .search_box>ul>li .txt + .sel_btn_box .custom_select{width: calc(100% - 70px);}
.mo_wrap .search_box>ul>li .txt + .sel_btn_box button{width: 65px;height: 36px;line-height: 34px;padding: 0;}
.mo_wrap .search_box>ul>li .custom_select{width: 180px;}
.mo_wrap .search_box>ul>li.item_li .txt + div>div:first-child{width: 100%;}
.mo_wrap .search_box>ul>li.item_li .txt + div>div:first-child .drop_wrap{width: calc(70% - 5px);}
.mo_wrap .search_box>ul>li .date_input{max-width: 100%;}
.mo_wrap .search_box>.btn_box button{width: calc((50% - 5px));}

/* drop_box */
.mo_wrap .drop_wrap{position: relative;}
.mo_wrap .drop_wrap>.custom_input input{cursor: default;padding:0 10px;font-size: 13px;}
.mo_wrap .drop_wrap .dim{position: fixed;width: 200%;height: 200%;top: 50%;left: 50%;transform: translate(-50%,-50%);background: transparent;}
.mo_wrap .drop_box {position: absolute;width: 100%;top: 36px;left: 0;border: 1px solid #ddd;background: #fff;z-index: 1;min-width: 110px;max-height: 200px;overflow-x: hidden;overflow-y: auto;}
.mo_wrap .drop_box2{top:30px;}
.mo_wrap .drop_box .list_ul>li{font-size: 13px;padding: 10px;border-top: 1px solid #ddd;text-align: left;cursor: default;color: #666;}
.mo_wrap .drop_box .list_ul>li:first-child{border-top: none;}
.mo_wrap .drop_box .list_ul>li:hover{background: #e3f0ff;color: #222;}
.mo_wrap .drop_box .list_ul>li.none,.mo_wrap .drop_box .list_ul2>li.none{text-align: center;padding: 30px 0;}
.mo_wrap .drop_box .list_ul>li.none:hover,.mo_wrap .drop_box .list_ul2>li.none:hover{color: #666;background: #fff;}
.mo_wrap .drop_box .list_ul2>li .txt{font-size: 13px;font-weight: 600;padding: 4px 10px;text-align: left;}
.mo_wrap .drop_box .list_ul2>li .list_ul>li{border-top: none;}
.mo_wrap .drop_box .new_box{padding:10px;}
.mo_wrap .drop_box .new_box p{font-size: 13px;text-align: left;padding-bottom: 5px;}
.mo_wrap .drop_box .new_box .custom_input2{width: calc(100% - 67px);}
.mo_wrap .drop_box .new_box .btn_type3{padding: 0;width: 60px;height: 40px;line-height: 39px;font-weight: 400;}

/* custom_table */
.mo_wrap .custom_table{border-top: 1px solid #222;}
.mo_wrap .custom_table th{font-size: 14px;}
.mo_wrap .custom_table tbody td{font-size: 14px;}
.mo_wrap .custom_table .farm_box{background: #777;color: #fff;font-size: 15px;font-weight: 500;text-align: center;padding: 10px;}
.mo_wrap .custom_table tr.tr_sky th,.mo_wrap .custom_table tr.tr_sky td{background: #00B2CD;color: #fff;font-weight: 700;}
.mo_wrap .custom_table2 tbody th{font-size: 14px;padding: 9px;}
.mo_wrap .custom_table2 tbody td{font-size: 14px;padding: 9px;}
.mo_wrap .list_result_box .custom_table{padding-bottom: 20px;border-bottom: 10px solid #F1F1F1;margin-bottom: 20px;}
.mo_wrap .list_result_box .custom_table:last-child{border-bottom: none;margin-bottom: 0;}

/* 회원가입 */
.mo_wrap .page_form{min-height: auto;padding-bottom: 30px;}
.mo_wrap .step_box{padding: 20px 0;}
.mo_wrap .step_box .tit{font-size: 16px;font-weight: 700;}
.mo_wrap .step_box .txt{font-size: 13px;padding-top: 10px;}
.mo_wrap .step_box .txt span{color: #666;}
.mo_wrap .step_box ul li {margin-right: 8px;width: 8px;height: 8px;border-radius: 50%;background: #999;}
.mo_wrap .step_box ul li:last-child {margin-right: 0;}
.mo_wrap .step_box ul li span{display: none;}
.mo_wrap .step_box ul li.on{width: 16px;height: 16px;background: #008ECD;text-align: center;line-height: 16px;}
.mo_wrap .step_box ul li.on span{display: block;font-size: 13px;font-weight: 700;color: #fff;}
.mo_wrap .page_form .gray_box{padding: 20px 15px 30px;margin-top: 0;}
.mo_wrap .page_form .terms_box .scroll_wrap p{font-size: 13px;}
.mo_wrap .form_ul>li{display: block;}
.mo_wrap .form_ul>li .input_tit{width: 100%;padding-top: 0;padding-bottom: 5px;font-size: 14px;}
.mo_wrap .form_ul>li>.box{width: 100%;}
.mo_wrap .form_ul>li>.box>*{width: 100%;}
.mo_wrap .form_ul>li>.box .btn_s2{height: 45px;width: 90px;padding:0;}
.mo_wrap .page_form .line_box{width: 100%;margin-top: 20px;}

/* 경매사 - 입하작업 */
.mo_wrap .cont_wrap2{display: block;}
.mo_wrap .cont_wrap2 .right_cont{width: 100%;}
.mo_wrap .cont_wrap2 .right_cont .top_box li{min-width: auto;width: calc(50% - 5px);font-size: 13px;}
.mo_wrap .cont_wrap2 .right_cont .bottom_box .many_btn_box button{font-size: 13px;padding: 0;width: calc(25% - 7.5px);}
.mo_wrap .media_info_box .img_ul li{width: calc(33.333% - 3.333px);height: 74px;border: 1px solid #ddd;}
.mo_wrap .media_info_box .img_ul li .img{width: 100%;height: 100%;background: #fff url(../../images/img_none.svg) no-repeat center;overflow: hidden;}
.mo_wrap .media_info_box .img_ul li:last-child .img{background: #fff url(../../images/video_upload.svg) no-repeat center;background-size: 25px;overflow: hidden;}

/* 경매사 - 온라인경매 상세 */
.mo_wrap .auction_wrap {position: relative; height: calc(100vh - 50px);}
.mo_wrap .auction_wrap .scroll_wrap{height: 140px;}
.mo_wrap .auction_wrap .right_cont{width: 100%;height: auto;}
.mo_wrap .memo_cont .black_box{background: #000;padding:3px 10px 10px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.mo_wrap .memo_cont .black_box .txt_box{width: 50%;}
.mo_wrap .memo_cont .black_box .txt_box .top_box{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.mo_wrap .memo_cont .black_box .txt_box .btn_memo{display: block;font-size: 13px;color: #000;font-weight: 500;background: #fff;width: 50px;height: 20px;line-height: 20px; text-align: center;}
.mo_wrap .memo_cont .black_box .txt_box p{font-weight: 500;color: #fff;padding-left: 10px;}
.mo_wrap .memo_cont .black_box .txt_box .yellow_txt{font-size: 18px;font-weight: 700;color: #FFE500;margin-top: 12px;padding-left: 0;height: 23px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.mo_wrap .memo_cont .black_box .num{width: 50%;height: 65px;position: relative;}
.mo_wrap .memo_cont .black_box .num::after{position: absolute;content: "";font-size: 45px;font-weight: 700;color: #fff;right: 0;top: 0;height: 100%;line-height: 65px;}
.mo_wrap .memo_cont .black_box .num.value1::after{content: "0";}
.mo_wrap .memo_cont .black_box .num.value2::after{content: "00";}
.mo_wrap .memo_cont .black_box .num.value3::after{content: "000";}
.mo_wrap .memo_cont .black_box .num input{width: 100%;height: 100%;font-size: 45px;font-weight: 700;color: #fff;text-align: right;background: none;border: none;box-shadow: none;}
.mo_wrap .memo_cont .black_box .num.value1 input{padding-right: 26px;}
.mo_wrap .memo_cont .black_box .num.value2 input{padding-right: 53px;}
.mo_wrap .memo_cont .black_box .num.value3 input{padding-right: 79px;}
.mo_wrap .memo_cont .btn_ul{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;border-top: 1px solid #ddd;}
.mo_wrap .memo_cont .btn_ul li{width: 25%;border-right: 1px solid #ddd;}
.mo_wrap .memo_cont .btn_ul li:last-child{border-right: none;}
.mo_wrap .memo_cont .btn_ul li button{display: block;font-size: 18px;font-weight: 500;color: #000;padding: 8px;text-align: center;background: #EDEDED;width: 100%;}
.mo_wrap .memo_cont .btn_ul li.on button{color: #fff;background: #666;}
.mo_wrap .keypad_cont{border-top: 1px solid #ddd;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;position: absolute;left: 0;bottom: 120px;height: calc(100% - 380px);width: 100%;}
.mo_wrap .keypad_cont li{width: 25%;border-right: 1px solid #ddd;border-bottom: 1px solid #ddd;}
.mo_wrap .keypad_cont li:nth-child(4n){border-right: none;}
.mo_wrap .keypad_cont li:nth-child(4n) button{font-size: 18px;background: #668E9F;color: #fff;}
.mo_wrap .keypad_cont li:nth-child(13) button{font-size: 18px;}
.mo_wrap .keypad_cont li:last-child{border-right: none;width: 50%;}
.mo_wrap .keypad_cont li:last-child button{font-size: 18px;color: #fff;background: #0F6186;}
.mo_wrap .keypad_cont li:last-child button span{display: inline-block; padding-left: 24px;background: url(../../images/btn_check.svg) no-repeat center left;}
.mo_wrap .keypad_cont li button{font-size: 28px;font-weight: 700;color: #000;text-align: center;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: center; -ms-flex-pack: center;-webkit-justify-content: center; justify-content: center;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;height: 100%;width: 100%;}
.mo_wrap .message_wrap{position: absolute;left: 0;bottom: 0;width: 100%;}
/* .mo_wrap .message_wrap .top_box{background: #000;overflow-x: hidden;overflow-y: auto;height: 74px;padding: 5px 10px;} */
.mo_wrap .message_wrap .top_box{background: #000;}
.mo_wrap .message_wrap .top_box .scroll_wrap{height: 74px;padding: 5px 10px;}
.mo_wrap .message_wrap .top_box .box>li{padding-left: 15px;background: url(../../images/mo/message_arrow.svg) no-repeat top 4px left;margin-bottom: 3px;color: #fff;font-size: 13px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: end; -ms-flex-align: end;-webkit-align-items: flex-end; align-items: flex-end;}
.mo_wrap .message_wrap .top_box .box>li.send{background: url(../../images/mo/message_arrow2.svg) no-repeat top 4px left;}
.mo_wrap .message_wrap .top_box .box>li .txt{max-width: calc(100% - 30px);}
.mo_wrap .message_wrap .top_box .box>li strong{font-weight: 700;padding-right: 5px;}
.mo_wrap .message_wrap .top_box .box>li .time{font-size: 10px;opacity: .6;padding-left: 5px;line-height: 1.5;}
.mo_wrap .message_wrap .bottom_box {display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;border-bottom: 1px solid #ddd;}
.mo_wrap .message_wrap .bottom_box .custom_input{width: calc(100% - 80px);}
.mo_wrap .message_wrap .bottom_box .custom_input input{height: 45px;border:none;}
.mo_wrap .message_wrap .bottom_box .btn_s{width: 80px;height: 45px;line-height: 45px;}
.mo_wrap .mo_auto_btn{position: absolute;top: 0;right: 0;height: 49px;padding-right: 5px;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;background: #fff;}
.mo_wrap .top_price_box{position: absolute;top: 0;left: 50%;height: 49px;line-height: 49px;text-align: center;width:calc(100% - 170px);transform: translateX(-50%);background: #fff;}
